const SESSION_STORAGE_KEY = {
    X_REQUEST_ID: 'X-Request_Id',
    APIM_KEY: 'APIM_Key',
    ACCOUNT_TYPE: 'accountType',
    IS_UMBRELLAFUND: 'Is_UmbrellaFund',
    THEME: "theme",
    RELOAD_SIGNUP: "reloadSignUp",
    RELOAD_FORGOT_PWD: "reloadForgotPwd",
    RELOAD_CONFIRM_MEMBER: "reloadConfirmMember",
    RELOAD_FACTORID: "reloadFactorId",
    RELOAD_STATETOKEN: "reloadStateToken",
    SESSIONID_TOKEN: 'sessionIdToken',
    SEND_ACTIVATION_EMAIL:'sendActivationEmail'
  };
  
  export default SESSION_STORAGE_KEY;
  