import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
    GelTooltip,
    GelRowLayout,
    GelLabel,
    GelContainerLite,
    GelCollapsible
} from '@tal-gel/components';
import BenefitType from '../../dashboard/benefitType';
import { getGelTokens } from '@tal-gel/theming';
import IPDetails from './ip-details';
import TPDDetails from './tpd-details';
import { CLAIM_TYPE } from '../../../constants/constants';
import { UserContext } from '../../../common/usercontext/user.context';

const ClaimBenefit = (props) => {

    const [showBenefitType, setShowBenefitType] = useState(false);

    const onHeaderClick = () => {
        setShowBenefitType(!showBenefitType);        
    }
    const StyledDiv = styled.div(() => ({
        padding:"0px",
        paddingBottom:"20px",
        width: getGelTokens().global.sizeBase * 50
      }));

    const StyledGelLabel = styled(GelLabel)(() => ({
    
    marginRight: getGelTokens().global.sizeBaseX2
    }));

    const StyledGelTooltip = styled(GelTooltip)(() => ({
    paddingBottom: 5
    }));

    return (
        <GelContainerLite style={{
            borderTop: `${getGelTokens().global.sizeBaseUnit/4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
            width: "auto",
            cursor: "pointer",
            padding:getGelTokens().global.sizeNone
        }}
            onClick={onHeaderClick}>
                
            <GelRowLayout style={{
                borderBottom: `${getGelTokens().global.sizeBaseUnit/4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderTop: `${getGelTokens().global.sizeBaseUnit/4}px solid ${getGelTokens().global.themeColorBorderDefault}`,

            }}>
                
                <BenefitType claimNumber={props.claimBenefit.caseNumber} description={props.claimBenefit.status.description}
                    friendlyStatus={props.claimBenefit.status.friendlyStatus}
                    benefitTypeText={props.claimBenefit.claimTypeName} showBenefitType={showBenefitType} isExpandable={true} />
            </GelRowLayout>

            <GelCollapsible open={showBenefitType}>
                <GelContainerLite style={{padding:"25px 40px"}}>
                     <GelRowLayout >
                     <StyledDiv>
                        <StyledGelLabel>{props.contentData.detailsHeader}</StyledGelLabel>
                        {(props.claimBenefit.claimTypeName === CLAIM_TYPE.IP) && <StyledGelTooltip selfAdaptive inline>
                            {props.contentData.disclaimer}
                        </StyledGelTooltip>}
                        </StyledDiv> 
                    </GelRowLayout> 
                    {(props.claimBenefit.claimTypeName === CLAIM_TYPE.IP) && 
                            <IPDetails details={props.claimBenefit.details} caseManager={props.claimBenefit.caseManager} contentData={props.contentData}/>}
                    {(props.claimBenefit.claimTypeName === CLAIM_TYPE.TPD || props.claimBenefit.claimTypeName === CLAIM_TYPE.TI) && 
                            <TPDDetails details={ props.claimBenefit.details} caseManager={props.claimBenefit.caseManager} contentData={props.contentData}/>}
                </GelContainerLite>
            </GelCollapsible>
            
        </GelContainerLite>
    );
};

export default ClaimBenefit;