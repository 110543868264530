import React, { useContext, useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelContainerLite,
} from '@tal-gel/components';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { CookieConfig, SessionManagement } from '../../constants/constants';

const HomePage = () => {
    const [ssoCookies, setSsoCookies] = useCookies(['aid', 'rid']);
    const navigate = useNavigate();
    const { oktaAuth, authState } = useOktaAuth();


    useEffect(() => {

        if (oktaAuth.isLoginRedirect())
            oktaAuth.handleLoginRedirect();
        else if (ssoCookies.aid && ssoCookies.rid){
            window.sessionStorage.setItem(SessionManagement.TokenReceivedOn, new Date().toString());
            window.sessionStorage.setItem(SessionManagement.LastActivity, new Date().toString());
            navigate("/claims");
        }
        else if (authState?.isAuthenticated) {
           
            setSsoCookies('aid', authState.accessToken?.accessToken, {
                domain: CookieConfig.DOMAIN,
                path: CookieConfig.PATH, secure: true, sameSite: 'lax',
                expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              });
              setSsoCookies('rid', authState.refreshToken?.refreshToken, {
                domain: CookieConfig.DOMAIN,
                path: CookieConfig.PATH, secure: true, sameSite: 'lax',
                expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              });
              
              window.sessionStorage.setItem(SessionManagement.TokenReceivedOn, new Date().toString());
              window.sessionStorage.setItem(SessionManagement.LastActivity, new Date().toString());

              navigate("/claims");
        } 
        
        setTimeout(() => {
            if (!authState?.isAuthenticated && !ssoCookies.aid && !ssoCookies.rid)
                navigate("/login");
          }, 1000)

       
    });

    return (
        <GelContainerLite gutter="xlarge" >
            <GelBoxLayout space="auto">
            </GelBoxLayout>
        </GelContainerLite >
    );
};

export default HomePage;