import React, { useContext, useEffect, useState } from 'react';
import {
  useGelFormData,
  GelForm, GelFormField, GelTextInput,
  GelButton,
  GelBoxLayout,
  GelContainerLite,
  GelSelect,
  GelIcon,
  GelLabel,
  GelPhoneNumberInput,
  GelScreenDetectorContext,
  GelRowLayout,
  GelStandardDateInput,
  GelReCaptcha,
  GelSpinner,
  GelParagraph,
  GelHeading3
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import { API, ReCaptchaSettings } from '../../constants/constants';
import axios from 'axios';
import { GET_SUPERFUND_DATA_QUERY } from '../../graphql/queries/graphql-queries.constant';
import { GET_CONFIRM_YOUR_DETAILS_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import FundBanner from '../common/fundBanner';
import { CONFIRM_USER_DETAILS, UPDATE_MOBILE_NUMBER } from '../../graphql/queries/graphql-mutations';
import { ConfirmUserStatus, UpdateMobileStatus } from '../../common/models/accountStatus.model';
import { UserContext } from '../../common/usercontext/user.context';
import { AccountApi } from '../../common/api/auth.provider';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { useCookies } from 'react-cookie';

const ConfirmMember = (props) => {

  const [superfundId, setSuperFund] = useState(null);
  const [superFundLoaded, setsuperFundLoaded] = useState(false);
  const [superFunds, setsuperFunds] = useState<null | { superFundList: any }>(null);
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcConfirmDetailsPageCollection: any }>(null);
  const navigate = useNavigate();
  const [confirmStatusMessage, setconfirmStatusMessage] = useState("");
  const [userDetailsVerified, setuserDetailsVerified] = useState(false);
  const [apiUpdateInProgress, setapiUpdateInProgress] = useState(false);
  const { contextData, setUserContext } = useContext(UserContext);
  const [status, setStatus] = useCookies(['confirmMember']);

  let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled == "1";

  const {
    formData,
    onFormDataChange,
    resetForm
  } = useGelFormData({
    reCaptchaToken: null,
    superFund: '',
    memberNumber: '',
    dateOfBirth: '',
    mobileNumber: ''
  });

  const onConfirmUserSubmit = () => {

    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER, "");

    setapiUpdateInProgress(true);
    setconfirmStatusMessage("");

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: superfundId
    };

    axios.post(API.BASEURL, {
      query: CONFIRM_USER_DETAILS,
      variables: {
        confirmUserRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setapiUpdateInProgress(false);
        if (response.data.data?.confirmUser?.statusCode == ConfirmUserStatus.MEMBER_VALID) {
          setuserDetailsVerified(true);
          window.sessionStorage.clear();
        } else {
          setStatus('confirmMember', contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.memberNotFoundMsg);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER, "reload");
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FACTORID, contextData.memberDetails?.factorId!);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_STATETOKEN, contextData.memberDetails?.stateToken!);
          navigate(0);
        }
      });
  };

  const onMobileUpdate = () => {

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: superfundId,
      mobile: formData.mobileNumber
    };

    setapiUpdateInProgress(true);

    axios.post(API.BASEURL, {
      query: UPDATE_MOBILE_NUMBER,
      variables: {
        updateMobileRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setapiUpdateInProgress(false);
        if (response.data.data?.updateMobileNumber?.statusCode == UpdateMobileStatus.MOBILE_UPDATED) {
          updateContext(response.data.data?.updateMobileNumber?.response, contextData.memberDetails?.stateToken!);

          AccountApi.sendMfaCode(response.data.data?.updateMobileNumber?.response, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
              console.log("sms sent")
            })
            .catch(httpError => {
              console.log('failed to send MFA sms', httpError);
            });

          navigate("/verifycode");
        }
        else
          setconfirmStatusMessage(contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.mobileNumberUpdateFailed);
      });
  }

  const updateContext = (factorId, stateToken) => {

    let maskedMob = formData.mobileNumber.replace(/^0/, '+61')
    maskedMob = maskedMob.replace(maskedMob.substring(3, 9), '******')

    setUserContext({
      memberDetails: {
        stateToken: stateToken,
        mobileNumber: maskedMob,
        factorId: factorId
      }, claims: []
    });

  }

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  }

  useEffect(() => {

    if (!isPageValidUpdateContext())
      navigate("/error");

    axios.post(API.CONTENTFUL_URL, {
      query: GET_CONFIRM_YOUR_DETAILS_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });

    axios.post(API.BASEURL, {
      query: GET_SUPERFUND_DATA_QUERY,
    })
      .then(async (response) => {
        setsuperFundLoaded(true);
        setsuperFunds(response.data?.data);
      });

    if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER) == "reload")
      setconfirmStatusMessage(status.confirmMember);

  }, []);

  const isPageValidUpdateContext = () => {
    return ((contextData.memberDetails?.stateToken) ||
      (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_STATETOKEN)));
  }

  if (!contentDataLoaded || !superFundLoaded)
    return <GelSpinner medium overlay />

  return (
    <GelContainerLite style={{
      paddingRight: getGelTokens().global.sizeNone,
      paddingLeft: getGelTokens().global.sizeNone,
      paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
      paddingTop: getGelTokens().global.sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        {!userDetailsVerified &&
          <GelForm
            labelAtTop={true}
            width={isXsScreen(screen) ?
              '100%' : getGelTokens().global.sizeBaseUnit * 110
            }
            {...!isXsScreen(screen) && {
              labelWidth: getGelTokens().global.sizeBaseUnit * 50
            }}
            fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
            disableOnSubmit
            onSubmit={onConfirmUserSubmit}
            reset={resetForm}
            parseResponseBody={false}
            style={{ 
              paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
              paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }} >
            <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
              <GelHeading3>
                {contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.headerText}
              </GelHeading3>
              <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2 }}>{contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.subText}</GelParagraph>

              <GelFormField
                label={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.superFundText}
              >
                <GelSelect
                  name="superfund"
                  onChange={onSuperFundChange}
                  placeholder="Please select"
                  options={superFunds?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                    { label: superFund.superfundName, value: superFund.superfundId }
                  ))}
                  required
                  requiredErrorMsg={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.superFundReqMsg}
                />
              </GelFormField>
              <GelFormField label={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.memberNumberText} >
                <GelTextInput
                  name="memberNumber"
                  onChange={onFormDataChange}
                  required
                  value={formData.memberNumber}
                  requiredErrorMsg={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.memberNumberReqMsg} />
              </GelFormField>
              <GelFormField
                label={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.dateOfBirthText} >
                <GelStandardDateInput
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={onFormDataChange}
                  required
                  lt="9999-12-31"
                  requiredErrorMsg={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.dobReqMsg}
                  errorMsg={{
                    required: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.dobReqMsg}`,
                    compare: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.dobFormatMsg}`,
                    format: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.dobFormatMsg}`
                  }}
                />
              </GelFormField>
              {isCaptchaEnabled &&
                <GelFormField
                  label={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.captchaText}>
                  <GelReCaptcha
                    name="reCaptchaToken"
                    sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                    onChange={onFormDataChange}
                    errorMsg={{
                      required: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.captchaReqMsg}`,
                    }}
                  />
                </GelFormField>}

              {confirmStatusMessage &&
                <GelBoxLayout space={[1, 8]}>
                  <GelIcon color={getGelTokens().global.themeColorIconDanger} name="AlertCircle" inline />
                  <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    {confirmStatusMessage}
                  </GelLabel>
                </GelBoxLayout>
              }
              {!apiUpdateInProgress &&
                <GelFormField width="auto" style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4, paddingBottom: getGelTokens().global.sizeBaseUnit * 4 }}>
                  <GelButton
                    name="" style={{
                      width: "100%"
                    }}
                    primary large submit
                  >
                    {contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.continueButtonText}
                  </GelButton>
                </GelFormField>}
              {apiUpdateInProgress &&
                <GelSpinner medium overlay={true} />}
            </GelRowLayout>
          </GelForm>}
        {userDetailsVerified &&
          <GelForm
            labelAtTop={true}
            width={isXsScreen(screen) ?
              '100%' : getGelTokens().global.sizeBaseUnit * 110
            }
            {...!isXsScreen(screen) && {
              labelWidth: getGelTokens().global.sizeBaseUnit * 50
            }}
            fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
            disableOnSubmit
            onSubmit={onMobileUpdate}
            reset={resetForm}
            parseResponseBody={false}
            style={{ 
              paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
              paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }}>
            <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
              <GelHeading3>
                {contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.updateMobileHeaderText}
              </GelHeading3>
              <GelParagraph>
                {contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.updateMobileSubText}
              </GelParagraph>

              <GelFormField label={contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.mobileNumberText} >
                <GelPhoneNumberInput
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={onFormDataChange}
                  required
                  errorMsg={{
                    required: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.mobileNumberReqMsg}`,
                    length: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.mobileNumberLengthMsg}`,
                    prefix: `${contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.errorMessageContent.mobileNumberPrefixMsg}`,
                  }}
                />
              </GelFormField>

              {!apiUpdateInProgress &&
                <GelFormField width="auto" style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4 }}>
                  <GelButton
                    name="" style={{
                      width: "100%",
                    }}
                    primary large submit
                  >
                    {contentfulData?.mmcConfirmDetailsPageCollection.items[0]?.continueButtonText}
                  </GelButton>
                </GelFormField>}
              {apiUpdateInProgress &&
                <GelSpinner medium overlay={true} />}
            </GelRowLayout>
          </GelForm>}

        {isLargerThanSmScreen(screen) &&
          <FundBanner></FundBanner>}

      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default ConfirmMember;