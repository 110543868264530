import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import UnAuthorized from '../../pages/common/unauthorized';
import { useCookies } from "react-cookie";

export const ProtectedRoute = () => {
  
  const { oktaAuth, authState } = useOktaAuth();
  const [ssoCookies] = useCookies(['aid', 'rid']);
  
  if((authState && authState.isAuthenticated) || 
      (ssoCookies.aid && ssoCookies.rid ))
    return (<Outlet />)
  else
    return (<UnAuthorized />);

}
