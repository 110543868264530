import React, { useContext } from "react";
import {
    GelRowLayout,
    GelParagraph,
    GelHeading3,
    GelHeading5,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useCookies } from "react-cookie";
import { AdodeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickEvents, AdobePageName } from "../../constants/constants";

const SupportLovedOnes = (props) => {
    const [ssoCookies] = useCookies(["tpid"]);
    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const ImageContainer = styled.div(() => ({
        position: "relative", textAlign: "center", backgroundSize: "cover",
        backgroundRepeat: "no-repeat", height: "168px", width: "100%", backgroundPosition: "center", borderRadius: getGelTokens().global.sizeBaseX2
    }));

    const onLinkClick = (linkText) => {
        AdodeAnalytics.PushCardClickEvent(ssoCookies.tpid, AdobeClickEvents.CardClick, AdobePageName.HealthSupport, 
            props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportLovedOnesHeader,
            linkText);
    }

    return (
        <GelContainerLite style={{ padding: `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeNone}px` }}>

            <GelRowLayout style={{ paddingBottom: getGelTokens().global.sizeBaseX9 }}>
                <GelHeading3>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportLovedOnesHeader}</GelHeading3>
            </GelRowLayout>

            <GelColumnLayout distribution={!isLargerThanLgScreen(screen) ? "1" : "1/3 1/3 1/3"}>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistLink} target="_blank"
                         onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistLinkText)}>
                        <ImageContainer id="carerAssist" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistImage.title}</GelHeading5>
                                <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>
                    <GelParagraph style={{ padding: `${getGelTokens().global.sizeBaseX4}px ${getGelTokens().global.sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistSubtext}</GelParagraph>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistLink} target="_blank"
                     onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].carerAssistLinkText}</GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportLinkText)}>
                        <ImageContainer id="griefSupport" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportImage.url})` }}>                            
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportImage.title}</GelHeading5>
                                <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>
                    <GelParagraph style={{ padding: `${getGelTokens().global.sizeBaseX4}px ${getGelTokens().global.sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportSubtext}</GelParagraph>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].griefSupportLinkText}</GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDLinkText)}>
                        <ImageContainer id="doD" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDImage.title}</GelHeading5>
                                <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>
                    <GelParagraph style={{ padding: `${getGelTokens().global.sizeBaseX4}px ${getGelTokens().global.sizeNone}px` }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDSubtext}</GelParagraph>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].doDLinkText}</GelLink>
                </GelContainerLite>
                
            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default SupportLovedOnes;