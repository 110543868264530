import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import axios from 'axios';
import { API, SessionManagement } from '../../constants/constants';
import { GET_REFRESH_SESSION_QUERY } from '../../graphql/queries/graphql-queries.constant';
import { useCookies } from 'react-cookie';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';

const useUserIdleTimer = (timeoutMin: number, onIdle: () => void) => {

    const timeout = timeoutMin * 60 * 1000;
    const [lastActivity, setLastActivity] = useState<number>(Date.now());
    const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);

    const refreshToken = () => {
        axios.post(API.BASEURL, {
            query: GET_REFRESH_SESSION_QUERY,
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
    }

    useEffect(() => {

        const handleUserActivity = throttle((event: MouseEvent) => {
            window.sessionStorage.setItem(SessionManagement.LastActivity, new Date().toString());
        }, 500);

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('scroll', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        const intervalId = setInterval(() => {

            if ((window.sessionStorage.getItem(SessionManagement.TokenReceivedOn))) {

                if((Date.now() - Date.parse(window.sessionStorage.getItem(SessionManagement.TokenReceivedOn)!) > timeout) && 
                    Date.now() - Date.parse(window.sessionStorage.getItem(SessionManagement.LastActivity)!) < timeout){
                    console.log("refreshToken triggered");
                    window.sessionStorage.removeItem(SessionManagement.TokenReceivedOn);
                    refreshToken();
                }
                else if(Date.now() - Date.parse(window.sessionStorage.getItem(SessionManagement.LastActivity)!) > timeout) {
                    console.log("onIdle triggered");
                    window.sessionStorage.removeItem(SessionManagement.TokenReceivedOn);
                    onIdle();
                } 
            }

        }, 1000);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            clearInterval(intervalId);
        };
    }, [lastActivity, onIdle, timeout]);

    return;
};

export default useUserIdleTimer;
