import React, { useCallback, useContext, useEffect, useState } from 'react';
import './App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';
import { Security } from '@okta/okta-react';
import {
  GelPageLayout,
  GelPageContent,
  GelPageFooter,
  GelPageHeader,
  GelPageSideNav,
  GelScreenDetectorContext,
  GelSpinner
} from '@tal-gel/components';
import Header from './pages/common/header';
import Footer from './pages/common/footer';
import AppRoutes from './pages/app.routing';
import { getGelTokens } from '@tal-gel/theming';
import { useCookies } from 'react-cookie';
import { PageSideNavStyle, PageSideNavXsStyle } from './pages/styles/nav.styles';
import PageNavigation from './pages/common/nav-menu/page-nav';
import axios, { HttpStatusCode } from 'axios';
import { ADOBE_ANALYTICS_SOURCE, API } from './constants/constants';
import { GET_MEMBER_DATA_QUERY } from './graphql/queries/graphql-queries.constant';
import { UserContext } from './common/usercontext/user.context';
import HttpClientService from './common/api/http-client.provider';
import { MemberRecord } from './common/models/memberclaims';
import { SET_USER_SESSION } from './graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from './constants/storage.constant';
import useUserIdleTimer from './common/useractivity/useUserIdleTimer';
import TimeOutModal from './pages/common/TimeOutModal';
import { loadScript } from './common/analytics/load-script';

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {

  const navigate = useNavigate();
  const [ssoCookies,setssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const { contextData, setUserContext } = useContext(UserContext)
  const [memberDataLoaded, setmemberDataLoaded] = useState(false);
  const [showNavigation, setshowNavigation] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleIdle = () => {
    setOpenModal(true);
  };

  useUserIdleTimer(10, handleIdle);

  const restoreOriginalUri = useCallback(
    (oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      })
    },
    [],
  );

  useEffect(() => {
    setmemberDataLoaded(false);

    loadScript(ADOBE_ANALYTICS_SOURCE)
    .then((script) => {
      if (ssoCookies.aid && ssoCookies.rid) {
        var headers = {
          'x-aid': ssoCookies.aid,
          'x-rid': ssoCookies.rid,
          'ssoid': ssoCookies.ssoid,
          'tpid': ssoCookies.tpid,
          'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
        };
  
        axios.post(API.BASEURL, {
          query: SET_USER_SESSION,
        },
          {
            headers: headers,
            withCredentials: true
          })
          .then(async (response) => {
            if (response.data.data?.createUserSession?.sessionCreated) {

            if(!ssoCookies.tpid)
              setssoCookies('tpid', response.data.data?.createUserSession?.superFundPrefix.toLowerCase());
              
              window.sessionStorage.setItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN, response.data.data?.createUserSession?.sessionIdToken);
  
              axios.post(API.BASEURL, {
                query: GET_MEMBER_DATA_QUERY,
              },
                {
                  headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': response.data.data?.createUserSession?.sessionIdToken
                  }
                })
                .then(async (response) => {
                  updateUserContext(response.data.data?.memberRecord);
                  setmemberDataLoaded(true);
  
                  if (response.data.data?.memberRecord?.claims?.length > 0) {
                    var unfinishedClaim = response.data.data?.memberRecord.claims.filter(claim => claim.status.description != 'Claim finalised');
                    if (unfinishedClaim) {
                      setshowNavigation(true);
                    }
                    else { navigate("/noClaims"); }
                  }
                  else { 
                  console.log("No claims found. navigating to no claims");
                  navigate("/noClaims"); 
                }
                })
            }
            else
              navigate("/error", { state: { errorCode: HttpStatusCode.Forbidden } });
          });
      }
      else {
        setmemberDataLoaded(true);
      }
    })
    .catch((error) => {
      console.error('Script loading failed:', error);
    });

   
  }, [ssoCookies]);

  const updateUserContext = (memberRecord) => {
    let memberDetails: MemberRecord = {};
    memberDetails.memberNumber = memberRecord?.memberNumber;
    memberDetails.policyManagerId = memberRecord?.policyManagerId;
    memberDetails.memberId = memberRecord?.memberId;
    memberDetails.displayLegalDisclosure = memberRecord?.displayLegalDisclosure;
    memberDetails.unReadDigiCorroMessageCount = memberRecord?.unReadDigiCorroMessageCount;

    setUserContext({
      claims: memberRecord?.claims,
      yourDetails: memberRecord?.profile,
      memberDetails: memberDetails,
      otherDocuments: memberRecord?.documentsHistory?.value.filter((doc: any) => doc.isAssociatedRequirementFound === false),
    });
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <HttpClientService>
        <GelPageLayout >
          <GelPageHeader>
          {memberDataLoaded && <Header />}            
          </GelPageHeader>
          {showNavigation && !contextData.memberDetails?.displayLegalDisclosure &&
            <GelPageSideNav sliding miniOnClose topOnMobile maxHeightAtTop={getGelTokens().global.sizeBaseUnit * 120}
              style={{ ...!isXsScreen(screen) ? PageSideNavStyle : PageSideNavXsStyle }}  >
              <PageNavigation></PageNavigation>
            </GelPageSideNav>
          }
          <GelPageContent>
            {openModal && <TimeOutModal />}
            <AppRoutes />
            {!memberDataLoaded && <GelSpinner medium overlay />}
            <Outlet />
          </GelPageContent>
          <GelPageFooter>
            <Footer />
          </GelPageFooter>
        </GelPageLayout>
      </HttpClientService>
    </Security>
  );
}

export default App;