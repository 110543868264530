export const UPLOAD_DOCUMENT_MUTATION =
    `mutation ($file: Upload!, $requirements: String!) { uploadDocument(file: $file, requirements: $requirements){id,scanStatus} }`;

export const MARK_NOTIFICATION_READ =
    `mutation MarkNotificationsRead($markNotificationRequest : MarkNotificationRequestInput!)
    {
        markNotificationsRead(markNotificationRequest: $markNotificationRequest)
        {
            unreadCount
            list{
                notificationId
                claimId
                sourceId
                type
                title
                text
                createdOn
                readOn
            }
        }
     }`;

export const CONFIGURE_MFA =
    `mutation ConfigureUserMfa($mfaConfigRequest : MfaConfigRequestInput!)
    {
        configureUserMfa(mfaConfigRequest: $mfaConfigRequest)
        {
            isSuccess
        }
     }`;

export const ACTIVATION_EMAIL =
    `mutation SuccessfulRegistrationEmail($memberActivationSuccessModel : MemberActivationSuccessModelInput!) {
        successfulRegistrationEmail(memberActivationSuccessModel: $memberActivationSuccessModel)
        {
            response
        }
    }`;

export const REGISTER_USER =
    `mutation RegisterUser($registerUserRequest : UserDetailsRequestInput!){
        registerUser(registerUserRequest: $registerUserRequest){
            statusCode
        }   
    }`;

export const CONFIRM_USER_DETAILS =
    `mutation ConfirmUser($confirmUserRequest : UserDetailsRequestInput!){
        confirmUser(confirmUserRequest: $confirmUserRequest){
            statusCode
        }
    }`;

export const UPDATE_MOBILE_NUMBER =
    `mutation UpdateMobileNumber($updateMobileRequest : UserDetailsRequestInput!){
        updateMobileNumber(updateMobileRequest: $updateMobileRequest){
            response
            statusCode
        }
    }`;

export const PASSWORD_RESET_MAIL =
    `mutation SendPasswordResetMail($forgotPasswordRequest : ForgotPasswordRequestInput!){
        sendPasswordResetMail(forgotPasswordRequest: $forgotPasswordRequest){
        }
    }`;

export const SAVE_LEGAL_DISCLOSURE_QUERY = `mutation AcceptLegalDisclosureForMemberClaims {
        acceptLegalDisclosureForMemberClaims {
        }
      }`;

export const SET_USER_SESSION = `mutation CreateUserSession {
    createUserSession {
        sessionCreated
        sessionIdToken
        superFundPrefix
        }
     }`;