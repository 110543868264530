import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelLink,
    GelSpinner
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../../common/authentication/auth.service';
import { useEffect, useState } from 'react';
import axios from "axios";
import { API } from '../../constants/constants';
import { useCookies } from 'react-cookie';
import { GET_ERROR_PAGES_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries"

const UnAuthorized = () => {
    const navigate = useNavigate();
    const { isStandAloneAuthenticated, clearSession } = AuthenticationService();
    const [ssoCookies] = useCookies(["tpid"]);
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | {
        mmcErrorPagesCollection: any;
    }>(null);
    
    const navigateToLogin = () => {
        clearSession();
        navigate("/login");
      };

      useEffect(() => {
        axios
          .post(API.CONTENTFUL_URL, {
            query: GET_ERROR_PAGES_CONTENT_QUERY,
            variables: {
              fundName: ssoCookies.tpid,
            },
          })
          .then(async (response) => {
            setcontentDataLoaded(true);
            setcontenfulData(response.data?.data);
          });
      }, []);

      if (!contentDataLoaded) 
        return <GelSpinner medium overlay />;

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                    {contentfulData?.mmcErrorPagesCollection?.items[0].unAuthorizedMessage}
                     </GelLabel>
                    {isStandAloneAuthenticated && <GelLink onClick={navigateToLogin}>
                        {contentfulData?.mmcErrorPagesCollection?.items[0].loginLinkText} </GelLink>}
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default UnAuthorized;