import React from 'react';
import {
    GelRowLayout,
} from '@tal-gel/components';
import NotificationItem from './notification-item';
import { getGelTokens } from "@tal-gel/theming";

const NotificationList = (props) => {

    return (
        <div style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2, paddingBottom: getGelTokens().global.sizeBaseUnit }}>
            <GelRowLayout>
                {props.requirements?.map((req) => (
                       <NotificationItem key={req.id} requirement={req} contentData={props.contentData}
                            memberNumber={props.memberNumber} superfundId={props.superfundId}>
                        </NotificationItem>
                ))}
            </GelRowLayout>
        </div>
    );
};

export default NotificationList;