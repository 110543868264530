export enum UserRegistrationStatus{
    REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS",
    SEND_ACTIVATION_EMAIL_FAILED = "SEND_ACTIVATION_EMAIL_FAILED",
    MEMBER_NOT_FOUND = "MEMBER_NOT_FOUND",
    USER_ACTIVATION_EMAIL_SENT = "USER_ACTIVATION_EMAIL_SENT",
    USER_ALREADY_ACTIVE = "USER_ALREADY_ACTIVE",
    USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS"
  }

export enum ConfirmUserStatus{
  MEMBER_NOT_FOUND = "MEMBER_NOT_FOUND",
  MEMBER_VALID = "MEMBER_VALID"
}

export enum UpdateMobileStatus{
  MOBILE_UPDATED = "MOBILE_UPDATED",
  MOBILE_NOT_UPDATED = "MOBILE_NOT_UPDATED"
}

export enum PasswordResetStatus{
  TOKEN_GENERATED_EMAIL_NOT_SENT = "TOKEN_GENERATED_EMAIL_NOT_SENT",
  SENDING_FORGOT_PASSWORD_EMAIL_SUCCESS = "SENDING_FORGOT_PASSWORD_EMAIL_SUCCESS"
}