import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';

const Activate = (props) => {
    const navigate = useNavigate();
    const [loginStatusMessage, setloginStatusMessage] = useState("");
    const { setUserContext } = useContext(UserContext);

    let { recoverytoken } = useParams();
    let restPasswordStateToken = ""; 
    let userId = "";


    useEffect(() => {
        if(recoverytoken)
        {
            AccountApi.verifyRecoveryToken(recoverytoken)
                    .then(response => response.json())
                    .then(data => {
                        console.log('verifyRecoveryToken data',data);
                        if(!data.errorCode){
                            restPasswordStateToken = data.stateToken
                            userId = data._embedded.user.profile.login; 
                            setUserContext({ memberDetails: {factorId: '', stateToken: restPasswordStateToken, mobileNumber: '', oktaEmail: userId}, claims: [] });
                            navigate("/createPassword");
                        }
                        else{
                            console.log('verifyRecoveryToken data failed');
                            navigate("/confirmMember");
                        }
                    })
                    .catch(httpError => {
                       console.log('verifyRecoveryToken Failed. Please try again',httpError);
                       setloginStatusMessage("Failed verifyRecoveryToken. Please try again");
                    });
        }
    },[]);

    return (
        <></>
    )
};

export default Activate;