import moment from 'moment';
import numeral from 'numeral';

export class FormatHelper {
  static formatDate = date => {
    return (moment(date, 'DD/MM/YYYY', true).format('MMMM D, Y'));
  };

  static formatShortDate = date => {
    return (moment(date, 'DD/MM/YYYY', true).format('D MMM YY'));
  };

  static formatAmount = amount => {
    return (numeral(amount).format('$0,0[.]00'));
  };

  static formatFileSize = size => {
    return (numeral(size).format('0 b'));
  };

  static toDate = dateStr => {
    return moment(dateStr, 'DD/MM/YYYY', true).toDate();
  }

  static daysDifference = date => {
    const fromDate = moment();
    const toDate = moment(date, 'DD-MM-YYYY');   
    const duration = moment.duration(fromDate.diff(toDate));
    const dayText = 'd ';
    const monthText = 'm ';
    const yearText = 'y ';    
    const days = duration.days() > 0 ? duration.days() + dayText : '';
    const months = duration.months() > 0? duration.months() + monthText : '';
    const years = duration.years() > 0? duration.years() + yearText : '';

    const daysDiff = fromDate.diff(toDate)/ (1000 * 3600 * 24);

    if(daysDiff > 7)
      return date;
    else
      return duration.days() >0 ||  duration.months() > 0 || duration.years() > 0 ? years + months + days +'ago' : 'Today';  
  };

  static stringFormat(template: string, ...args: any[]) {
    return template?.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
  };
}
