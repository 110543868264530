import React, { useContext, useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link } from "react-router-dom";
import {
    NavItemList,
    NavMenuContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    StyledListHeader,
    StyledListItem
} from "../../styles/nav.styles";
import { NavProps } from "./nav-props";
import { AdodeAnalytics } from '../../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { AdobeClickEvents, AdobePageName } from '../../../constants/constants';

const MiniSideNav = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {

    const[selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const [ssoCookies] = useCookies(["tpid"]);
    
     const onMenuItemClick = (itemId) => {
        const item = textData.menuItems.find(item => item.id == itemId);
        AdodeAnalytics.PushNavClickEvent(ssoCookies.tpid, AdobePageName.MenuItem,item.text);
        setSelectedMenuItem(item);
    };

    useEffect(() => {
        setSelectedMenuItem(activeMenuItem)
    })

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelIcon
                        name="MinimalRight"
                        width={getGelTokens().global.sizeBaseUnit * 6}
                        style={{ float: "right" }}
                        color={getGelTokens().global.themeColorWhite}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                 style= { (menuItem.id == selectedMenuItem?.id) ? ActiveMenuStyle: EnabledMenuStyle} >
                                <GelBoxLayout>
                                    <GelIcon
                                        name={menuItem.icon}
                                        color={(menuItem.id == selectedMenuItem?.id) ? getGelTokens().global.themeColorBackgroundSuccess :
                                            getGelTokens().global.themeColorGrayT30}
                                        width={getGelTokens().global.sizeBaseUnit * 6}
                                        style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}
                                    />
                                </GelBoxLayout>
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuContainer>
        </GelRowLayout>
    );
};

export default MiniSideNav;
