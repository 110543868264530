import React, { useEffect } from 'react';
import {
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelIcon,
  GelLabel,
  GelLink,
  GelTag
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { CLAIM_TYPE, ContextType, NotificationItemIds } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const NextPayment = (Props) => {
    const [claimCookie, setClaimCookie] = useCookies(['selectedClaim']);
    const navigate = useNavigate();
    useEffect(() => {
    });   

    const navigateToDetails = () => {
        setClaimCookie('selectedClaim',  Props.claimType);
        navigate("/claimdetails", { state: { selectedNotification: NotificationItemIds.FuturePayment } });
      };

    const requirements =     
    Props.isFuturePayment && Props.claimType == CLAIM_TYPE.IP &&
        <GelBoxLayout alignmentIndividual={['center', null]} space={[0.1, 7]} 
            style={{
                backgroundColor: getGelTokens().global.themeColorBackgroundLight,
                paddingTop: getGelTokens().global.sizeBaseX3,
                paddingBottom: getGelTokens().global.sizeBaseX3,
                border: `0px solid ${getGelTokens().global.themeColorGrayT10}`,
                borderWidth: `0px 0px 1px`
            }}>
            <GelIcon
                name="Cost"
                width={getGelTokens().global.sizeBaseUnit * 5}
                color={getGelTokens().global.themeColorBackgroundDark} style={{marginLeft:getGelTokens().global.sizeBaseX2}}></GelIcon>
            <GelLink onClick={navigateToDetails}>{Props.message}{Props.futurePayments.netAmount}</GelLink>            
        </GelBoxLayout>
    

    return (
        <GelContainerLite gutter="medium"style={{
            paddingTop: 0,
            paddingBottom: getGelTokens().global.sizeBaseX2 * 1,
            paddingLeft: getGelTokens().global.sizeBaseX2 * 1,
            paddingRight: getGelTokens().global.sizeBaseX2 * 1,
            }}>      
            {requirements}
        </GelContainerLite>
      );
};

export default NextPayment;