import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelLink
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import React from 'react';

const PageNotFound = () => {

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                        The requested page is not valid. Please check the URL and try again.
                    </GelLabel>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default PageNotFound;