export const SaveFile = (byteData:any, fileName: string) => {
    var binary = '';
    var bytesArray = new Uint8Array(byteData);
    var len = bytesArray.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytesArray[i]);
    }
    var base64String = window.btoa(binary);
    const binaryString = window.atob(base64String);
    const bytes = new Uint8Array(binaryString.length);
    var responseByte = bytes.map((byte, i) => binaryString.charCodeAt(i));

    const blob = new Blob([responseByte]);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = fileName;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};