import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';

export const VerticalSeperator = styled.div((props: React.CSSProperties) => ({
    height: getGelTokens().global.sizeBaseUnit * 30,
    backgroundColor: getGelTokens().global.themeColorBorderDefault,
    marginLeft: getGelTokens().global.sizeBaseUnit * 5,
    marginRight: getGelTokens().global.sizeBaseUnit * 5,
    width: getGelTokens().global.sizeBaseUnit / 4
}));

export const HorizontalSeperator = styled.div((props: React.CSSProperties) => ({
    height: getGelTokens().global.sizeBaseUnit / 4,
    backgroundColor: getGelTokens().global.themeColorBorderDefault,
    width: "100%"
}));