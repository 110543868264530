import React, { useContext, useEffect, useState } from "react";
import { getGelTokens } from "@tal-gel/theming";
import {
  GelScreenDetectorContext,
  GelRowLayout,
  GelContainerLite,
  GelTabs,
  GelTab,
  GelHeading2,
  GelParagraph,
  GelSpinner
} from "@tal-gel/components";
import { UserContext } from "../../common/usercontext/user.context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClaimBenefit from "./policy/claimBenefit";
import {
  ACTIVITY_TYPE,
  AdobePageName,
  API,
  CLAIM_ASSESSMENT_STATUS,
  FuturePaymentNotificationId,
  NotificationType,
} from "../../constants/constants";
import { GET_CLAIMDETAILS_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import Card from "../../common/components/card";
import styled from "@emotion/styled";
import axios from "axios";
import { GET_MEMBER_NOTIFICATIONS_QUERY } from "../../graphql/queries/graphql-queries.constant";
import NotificationList from "./notifications/notification-list";
import { FormatHelper } from "../../common/formatHelper.common";
import ClaimHistory from "./history/claimHistory";
import { useCookies } from "react-cookie";
import OtherDocuments from "./otherDocuments/otherdocuments";
import ResponsiveContainer from "../common/responsive.container"; import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdodeAnalytics } from "../../common/analytics/adobe-analytics";


const TabBorderOverride = styled.div(() => ({
  margin: -getGelTokens().global.sizeBaseUnit / 4,
  border: getGelTokens().global.sizeNone
}));

const ClaimDetailsContainer = () => {
  const { screen, isXsScreen } = useContext(
    GelScreenDetectorContext
  ) as GelScreenDetectorContext;
  const { contextData, setUserContext } = useContext(UserContext);
  const location = useLocation();
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | {
    mmcClaimDetailsCollection: any;
  }>(null);
  const [notificationDataLoaded, setnotificationDataLoaded] = useState(false);
  const [ssoCookies] = useCookies(["aid", "rid", "ssoid", "tpid"]);
  const [claimCookie, setClaimCookie] = useCookies(["selectedClaim"]);
  const PageHeader = styled.div(() => ({
    padding: isXsScreen(screen)
      ? `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX5
      }px`
      : `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX10
      }px`,
    backgroundColor: getGelTokens().global.themeColorBackgroundLight,
  }));

  if (contextData.memberDetails?.displayLegalDisclosure)
    navigate("/yourDeclaration");

  useEffect(() => {
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_CLAIMDETAILS_CONTENT_QUERY,
        variables: {
          fundName: ssoCookies.tpid,
        },
      })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });
    loadNotifications();
    AdodeAnalytics.PushPageLoadEvent(ssoCookies.tpid, AdobePageName.ClaimDetails);
  }, []);

  const loadNotifications = () => {
    axios
      .post(
        API.BASEURL,
        {
          query: GET_MEMBER_NOTIFICATIONS_QUERY,
        },
        {
          headers: {
            'x-aid': ssoCookies.aid,
            'x-rid': ssoCookies.rid,
            'ssoid': ssoCookies.ssoid,
            'tpid': ssoCookies.tpid,
            'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
          }
        })
      .then(async (response) => {
        addNotificationsToContext(response.data?.data.newNotifications?.list?.filter(n => 
          (n.type == NotificationType.ProceesedPayment || n.type == NotificationType.Activity)));
        setnotificationDataLoaded(true);

        if (location.state?.selectedNotification) {
          setTimeout(() => {
            const element = document.getElementById(
              location.state?.selectedNotification
            );
            if (element) element.scrollIntoView({ behavior: "smooth" });
          }, 300);
        }
      });
  };

  const addNotificationsToContext = (notifications: any[]) => {
    removeProcessedNotificationFromContext();
    notifications?.forEach((nt) => {
      let claim = contextData.claims?.find(
        (claim) => claim.claimId == nt.claimId
      );

      if (
        claim &&
        claim.requirements.filter(
          (rq) =>
            rq.id == nt.notificationId 
        ).length == 0
      ) {

        claim?.requirements.push({
          description: nt.text,
          friendlyText: nt.title,
          type: nt.type == 1 ? NotificationType.ProceesedPayment : NotificationType.Activity,
          id: nt.notificationId,
          claimId: nt.claimId,
          requestedDate: nt.createdOn,
          completedDate: nt.readOn,
        });
      }
    });

    contextData.claims?.forEach((claim) => {
      if (claim.nextFuturePayment.isSuccess) {
        if (
          claim &&
          claim.requirements.filter(
            (rq) =>
              rq.id == FuturePaymentNotificationId &&
              rq.type == NotificationType.FuturePayment
          ).length == 0
        ) {
          claim.requirements.push({
            description: claim.nextFuturePayment.value.dispatchDate,
            friendlyText: claim.nextFuturePayment.value.netAmount.toString(),
            type: NotificationType.FuturePayment,
            id: FuturePaymentNotificationId,
            claimId: claim.claimId,
            requestedDate: claim.nextFuturePayment.value.createdDate,
          });
        }
      }
    });

    contextData.claims?.map((claim) => {
      claim.activities.map((ac) => (ac.hideActivity = false));
    });

    notifications?.forEach((nt) => {
      let claim = contextData.claims?.find(
        (claim) => claim.claimId == nt.claimId
      );
      if (claim){

        claim.activities
          .filter((at) => at.claimPaymentId == nt.sourceId)
          .map((x) => (x.hideActivity = true));

        if(nt.type == NotificationType.Activity){
          claim.activities
          .filter((at) => at.type == ACTIVITY_TYPE.ACTIVITY)
          .map((x) => (x.hideActivity = true));
        }
      }
    });

    contextData.claims?.forEach((claim) => {
      claim.requirements.sort(
        (a, b) =>
          FormatHelper.toDate(b.requestedDate).getTime() -
          FormatHelper.toDate(a.requestedDate).getTime()
      );
    });
    setUserContext(contextData);
  };

  const removeProcessedNotificationFromContext = () => {
    contextData.claims?.forEach((claim) => {
      claim.requirements
        .filter((rq) => rq.type == NotificationType.ProceesedPayment || rq.type == NotificationType.Activity)
        .forEach((ppay) => {
          claim.requirements.splice(claim.requirements.indexOf(ppay), 1);
        });
    });
  };

  var selectedClaimType = claimCookie.selectedClaim;

  if (selectedClaimType) {
    const selectedclaimDetails = contextData.claims?.find(
      (c) => c.claimTypeName === selectedClaimType
    );
    var index = selectedclaimDetails
      ? contextData.claims.indexOf(selectedclaimDetails)
      : -1;
    let sortedClaimDetails = [...contextData.claims];
    if (selectedclaimDetails) {
      sortedClaimDetails.splice(index, 1);
      sortedClaimDetails.unshift(selectedclaimDetails);
      contextData.claims = sortedClaimDetails;
    }
  }

  if (!contentDataLoaded) return <GelSpinner medium overlay />;

  return (
    <GelContainerLite
      style={{ padding: getGelTokens().global.sizeNone, overflow: "hidden" }}
    >
      <PageHeader>
        <GelHeading2>
          {contentfulData?.mmcClaimDetailsCollection?.items[0].headerText}
        </GelHeading2>
        <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseX3 }}>
          {
            contentfulData?.mmcClaimDetailsCollection?.items[0]
              .headerTextDescription
          }
        </GelParagraph>
      </PageHeader>


      <GelTabs style={{ border: getGelTokens().global.sizeNone, marginRight: -getGelTokens().global.sizeBaseHalf }}>
        {contextData.claims?.map((claim, index) => (
          <GelTab
            key={Math.random()}
            title={claim.claimTypeName}
            tabindex={index}
            removeContentPadding
          >
            <TabBorderOverride>
              <ClaimBenefit
                claimBenefit={claim}
                contentData={
                  contentfulData?.mmcClaimDetailsCollection?.items[0]
                }
              ></ClaimBenefit>
              <ResponsiveContainer>
                <GelRowLayout
                  style={{
                    padding: isXsScreen(screen)
                      ? `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX5
                      }px`
                      : `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX10
                      }px`,
                  }}
                >
                  {claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN && (
                    <Card
                      title={
                        contentfulData?.mmcClaimDetailsCollection?.items[0]
                          .notificationsCardHeader
                      }
                      icon={"InfoCircle"}
                      subTitle={
                        contentfulData?.mmcClaimDetailsCollection?.items[0]
                          .notificationCardHeaderDescription
                      }
                      showFooter={false}
                    >
                      {!notificationDataLoaded &&
                        <div style={{ height: getGelTokens().global.sizeBaseX20 }}>
                          <GelSpinner medium overlay />
                        </div>}
                      {notificationDataLoaded && (
                        <NotificationList
                          requirements={claim.requirements}
                          contentData={contentfulData}
                          memberNumber={
                            contextData.memberDetails?.memberNumber
                          }
                          superfundId={
                            contextData.memberDetails?.policyManagerId
                          }
                        ></NotificationList>
                      )}
                    </Card>
                  )}
                  <Card
                    title={
                      contentfulData?.mmcClaimDetailsCollection?.items[0]
                        .otherDocumentsCardHeader
                    }
                    lightHeader={true}
                    subTitle={
                      contentfulData?.mmcClaimDetailsCollection?.items[0]
                        .otherDocumentsCardHeaderDescription
                    }
                    showFooter={false}
                  >
                    {notificationDataLoaded && (
                      <OtherDocuments
                        contentData={contentfulData}
                        claimId={claim.claimId}
                        claimAssesmentStatus={claim.claimAssesmentStatus}
                      ></OtherDocuments>
                    )}
                  </Card>
                  <Card
                    title={
                      contentfulData?.mmcClaimDetailsCollection?.items[0]
                        .claimsHistoryCardHeader
                    }
                    lightHeader={true}
                    subTitle={
                      contentfulData?.mmcClaimDetailsCollection?.items[0]
                        .claimsHistoryCardHeaderDescription
                    }
                    showFooter={false}
                  >
                    {!notificationDataLoaded && <div style={{ height: getGelTokens().global.sizeBaseX20 }}>
                      <GelSpinner medium overlay />
                    </div>}
                    {notificationDataLoaded && (
                      <ClaimHistory
                        claimDetails={claim}
                        contentData={contentfulData}
                      ></ClaimHistory>
                    )}
                  </Card>

                </GelRowLayout>
              </ResponsiveContainer>
            </TabBorderOverride>
          </GelTab>
        ))}
      </GelTabs>

    </GelContainerLite>
  );
};

export default ClaimDetailsContainer;
