import { ReactNode, createContext, useState } from "react";
import { DocumentsHistory, MemberClaims, MemberRecord, Profile } from "../models/memberclaims";

type Props = {
    children?: ReactNode;
}

export type UserContext = {
    memberDetails?: MemberRecord;
    memberId?: number;
    claims: MemberClaims[];
    yourDetails?: Profile;
    otherDocuments?: DocumentsHistory[];
}


type IUserContext = {
    contextData: UserContext

    setUserContext: (contextData: UserContext) => void
}

const initialValue:IUserContext = {
    
    contextData: {
        memberDetails: {} as MemberRecord,
        claims: [] as any[],
        yourDetails: {} as Profile,
        otherDocuments: [] as any[],
    },
    setUserContext: () => { }
}

const UserContext = createContext<IUserContext>(initialValue);

const UserContextProvider = ({ children }: Props) => {
    const [contextData, setUserContext] = useState(initialValue.contextData)

    return (
        <UserContext.Provider value={{ contextData, setUserContext }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserContextProvider }