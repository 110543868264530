import React, { useContext } from 'react';
import {
    GelRowLayout,
    GelLabel,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { HorizontalSeperator, VerticalSeperator } from './dividers';
import styled from '@emotion/styled';

const ClaimConsultantDetails = (props) => {
    const StyledGelLabel = styled(GelLabel)(() => ({
        fontWeight: "400"
    }));
    return (
        <GelRowLayout style={{ paddingBottom: getGelTokens().global.sizeBaseX5, minWidth: getGelTokens().global.sizeBaseX5 * 5 }} gutter="small">
            <GelBoxLayout>
                <StyledGelLabel small id={"caseManagerLabel"}>{props.contentData.caseManager}</StyledGelLabel>
                <GelParagraph small id={"caseManagerValue"} style={{ display: "flex", justifyContent: "end", fontWeight: "400" }}>
                    {props.caseManager === undefined ? props.contentData.defaultCaseManager : props.caseManager}
                </GelParagraph>
            </GelBoxLayout>
        </GelRowLayout>
    )
}

const TpdBenefitDetails = (props) => {
    const StyledGelLabel = styled(GelLabel)(() => ({
        fontWeight: "400"
    }));
    return (
        <GelRowLayout style={{ paddingBottom: getGelTokens().global.sizeBaseX5, minWidth: getGelTokens().global.sizeBaseX5 * 5 }} gutter="small">
            <GelBoxLayout>
                <StyledGelLabel small id={"benefitAmountLabel"}>{props.contentData.benefitAmount}</StyledGelLabel>
                <GelParagraph small id={"benefitAmountValue"} style={{ display: "flex", justifyContent: "end", fontWeight: "400" }}>
                    {props.details?.sumInsured?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    })}</GelParagraph>
            </GelBoxLayout>
        </GelRowLayout>
    )
}

const TpdPolicyDetails = (props) => {
    const StyledGelLabel = styled(GelLabel)(() => ({
        fontWeight: "400"
    }));
    return (
        <GelRowLayout style={{ paddingBottom: getGelTokens().global.sizeBaseX5 }} gutter="small">
            <GelBoxLayout>
                <StyledGelLabel small id={"policyOwnerLabel"}>{props.contentData.policyOwner}</StyledGelLabel>
                <GelParagraph small id={"policyOwnerValue"} style={{ display: "flex", textAlign: "end", justifyContent: "end", fontWeight: "400" }}>{props.details?.policyOwner}</GelParagraph>
            </GelBoxLayout>
            <GelBoxLayout>
                <StyledGelLabel small id={"policyNumberLabel"}>{props.contentData.policyNumber}</StyledGelLabel>
                <GelParagraph small id={"policyNumberValue"} style={{ display: "flex", justifyContent: "end", fontWeight: "400" }}>{props.details?.masterPolicyNo}</GelParagraph>
            </GelBoxLayout>
            <GelBoxLayout>
                <StyledGelLabel small id={"lifeInsuredLabel"}>{props.contentData.lifeInsured}</StyledGelLabel>
                <GelParagraph small id={"lifeInsuredValue"} style={{ display: "flex", justifyContent: "end", fontWeight: "400" }}>{props.details?.lifeInsured}</GelParagraph>
            </GelBoxLayout>
        </GelRowLayout>
    )
}

const TPDDetails = (props) => {
    const Spacer = styled.div(() => ({
        paddingTop: getGelTokens().global.sizeBaseX4
    }));
    const { screen, isXsScreen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    if (isXsScreen(screen)) {
        return (
            <GelRowLayout>
                <TpdPolicyDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
                <HorizontalSeperator />
                <Spacer></Spacer>
                <TpdBenefitDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
                <HorizontalSeperator />
                <Spacer></Spacer>
                <ClaimConsultantDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
            </GelRowLayout>
        );
    }
    else {
        return (
            <GelBoxLayout space={[5, 0.5, 5, 0.5, 5]} alignment="start">
                <TpdPolicyDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
                <VerticalSeperator style={{ height: !isLargerThanLgScreen(screen) ? getGelTokens().global.sizeBaseUnit * 45 : getGelTokens().global.sizeBaseUnit * 30 }} />
                <TpdBenefitDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
                <VerticalSeperator style={{ height: !isLargerThanLgScreen(screen) ? getGelTokens().global.sizeBaseUnit * 45 : getGelTokens().global.sizeBaseUnit * 30 }} />
                <ClaimConsultantDetails details={props.details} caseManager={props.caseManager} contentData={props.contentData} />
            </GelBoxLayout>
        );
    }

};

export default TPDDetails;