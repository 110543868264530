import React from 'react';
import {
    GelRowLayout
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import RequirementDocuments from '../documentsupload/requirementDocuments';
import NextFuturePayment from '../nextFuturePayment/nextFuturePayment';
import ProcessedPayment from '../processedPayment/processedPayment';
import { NotificationType } from '../../../constants/constants';
import StatusUpdates from '../statusUpdate/statusUdpates';

interface NotificationItemProps {
    requirement: any;
    contentData: any;    
    memberNumber:string;
    superfundId:number;
}

const NotificationItem = (props:NotificationItemProps) => {

    return (
        <GelRowLayout style={{
            paddingBottom: getGelTokens().global.sizeBaseUnit * 4,
            paddingLeft: getGelTokens().global.sizeBaseUnit * 4,
            paddingRight: getGelTokens().global.sizeBaseUnit * 4,
        }}>
            {(props.requirement.type == NotificationType.ProceesedPayment) &&
                <ProcessedPayment key={props.requirement.id} payment={props.requirement} contentData={props.contentData}
                    notificationId={props.requirement.id}></ProcessedPayment>}
            
            {(props.requirement.type == NotificationType.Activity) &&
                <StatusUpdates key={props.requirement.id} activity={props.requirement} contentData={props.contentData}
                    notificationId={props.requirement.id}></StatusUpdates>}

            {props.requirement.type == NotificationType.FuturePayment &&
                     <NextFuturePayment futurePayment={props.requirement} contentData={props.contentData}></NextFuturePayment>
                }   
            {(props.requirement.type != NotificationType.ProceesedPayment 
                 && props.requirement.type != NotificationType.Activity
                 && props.requirement.type != NotificationType.FuturePayment
                 && !props.requirement.completedDate) &&
                <RequirementDocuments key={props.requirement.id}
                    contentFullData={props.contentData?.mmcClaimDetailsCollection?.items[0]}
                    requirement={props.requirement} 
                    memberNumber={props.memberNumber} superfundId={props.superfundId}>
                </RequirementDocuments>
            }
        </GelRowLayout>
    );
};

export default NotificationItem;