import { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { CookieConfig } from '../../constants/constants';
import { UserContext } from '../usercontext/user.context';
import { useOktaAuth } from '@okta/okta-react';

function AuthenticationService() {
    const [ssoCookies, setSsoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid', 'sid']);
    const [isStandAloneAuthenticated, setIsStandAloneAuthenticated] = useState(false);
    const [isIntegratedAuthenticated, setIsIntegratedAuthenticated] = useState(false);
    const { contextData, setUserContext } = useContext(UserContext);
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {

        if (ssoCookies.aid && ssoCookies.rid  && !ssoCookies.ssoid) 
            setIsStandAloneAuthenticated(true);
        else 
            setIsStandAloneAuthenticated(false);

        if (ssoCookies.aid && ssoCookies.rid  && ssoCookies.ssoid && ssoCookies.tpid) 
            setIsIntegratedAuthenticated(true);
        else 
            setIsIntegratedAuthenticated(false);

    }, [ssoCookies]);

    const clearSession = () => {
        window.sessionStorage.clear();

        setSsoCookies('aid', "", {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime())
        });
        setSsoCookies('rid', "", {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime())
        });

        setUserContext({ claims: [], yourDetails: undefined });

        if(isStandAloneAuthenticated)
            oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
    }

    return { isStandAloneAuthenticated, isIntegratedAuthenticated, clearSession };
}

export default AuthenticationService;