// utils/loadScript.ts
export const loadScript = (src: string): Promise<HTMLScriptElement> => {
    return new Promise((resolve, reject) => {

        // Check if the script is already present
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript || src.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            console.log('Adobe script not loaded as ADOBE_ANALYTICS_URL is not replaced or already loaded');
            resolve(existingScript as HTMLScriptElement); // Script is already loaded, resolve immediately
            return;
        }

        // Create a new script element
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script); // Resolve the promise when script loads
        script.onerror = (error) => reject(error); // Reject the promise if there's an error

        // Append the script to the document head
        document.head.appendChild(script);
    });
};
