import {
  GelButton,
  GelIcon,
  GelParagraph,
  GelRow,
  GelCol,
  GelSpinner,
  GelScreenDetectorContext,
  GelBoxLayout,
  GelTag,
  GelLabel,
  GelForm,
  GelFormField,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useEffect, useState } from "react";
import {
  API,
  CookieConfig,
  DIGITAL_FORM_TYPE,
  FILE_UPLOAD_STATUS,
  TFN_DETAILS_REQUIREMENTS,
} from "../../../constants/constants";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { CONFIGURE_MFA } from "../../../graphql/queries/graphql-mutations";
import { useNavigate } from "react-router-dom";
import { DocumentModel } from "../../../common/models/document.model";
import DocumentDownload from "../documentDownload/documentDownload";
import SESSION_STORAGE_KEY from "../../../constants/storage.constant";

interface OtherRequirementProps {
  requirement: any;
  contentFullData: any;
  memberNumber: string;
  requirementData?: DocumentModel;
}

const OtherRequirement = (props: OtherRequirementProps) => {
  const navigate = useNavigate();
  const [ssoCookies, setSsoCookies] = useCookies([
    "aid",
    "rid",
    "ssoid",
    "tpid",
    "sid",
  ]);
  var [fileList, setFileList] = useState<any>([]);

  let files = fileList ? [...fileList] : [];
  useEffect(() => {
    if (props.requirement?.associatedDocuments) {
      if (props.requirement.associatedDocuments.length > 0) {
        fileList = [];
        setFileList([
          ...fileList,
          ...props.requirement.associatedDocuments.map(toFile),
        ]);
      }
    }
  }, []);

  const toFile = (file: any) => {
    return {
      id: file.fileId,
      status: FILE_UPLOAD_STATUS.SUCCESS,
      name: file.displayName,
      sharePointDocumentIdentifier: file.sharePointDocumentIdentifier,
      displayName: file.displayName,
    };
  };

  const onSubmit = () => {
    var aid = ssoCookies.aid;
    const user = jwtDecode(aid);
    window.history.pushState({}, "", window.location.href);
    var micrositeBaseUrl = TFN_DETAILS_REQUIREMENTS.includes(
      props.requirement.description
    )
      ? API.TFN_REDIRECT
      : API.EFT_REDIRECT;
    var micrositeLoginRoute = "verify-passcode";

    setSsoCookies(
      "sid",
      window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN),
      {
        domain: CookieConfig.DOMAIN,
        path: CookieConfig.PATH,
        secure: true,
        sameSite: "lax",
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      }
    );

    if (props.memberNumber.includes("AutomationCA")) {
      micrositeLoginRoute = "automation-login";
      var origin = btoa(window.location.href);
      if (window.location.href.indexOf("?source=") > 0) {
        var url = window.location.href.split("?", 1);
        origin = btoa(url[0]);
      }
      var paramValue =
        user.sub +
        "_" +
        props.requirement.claimId +
        "_" +
        props.requirement.id +
        "_" +
        props.memberNumber;
      const redirectUrl = `${micrositeBaseUrl}/#/${micrositeLoginRoute}?info=${paramValue}&origin=${origin}`;
      redirect(redirectUrl);
    } else {
      let requestData = {
        userName: user.sub,
        memberNumber: props.memberNumber,
        source: TFN_DETAILS_REQUIREMENTS.includes(props.requirement.description)
          ? DIGITAL_FORM_TYPE.TFN
          : DIGITAL_FORM_TYPE.EFT,
      };
      axios
        .post(
          API.BASEURL,
          {
            query: CONFIGURE_MFA,
            variables: {
              mfaConfigRequest: requestData,
            },
          },
          {
            headers: {
              "x-aid": ssoCookies.aid,
              "x-rid": ssoCookies.rid,
              ssoid: ssoCookies.ssoid,
              tpid: ssoCookies.tpid,
              sid: window.sessionStorage.getItem(
                SESSION_STORAGE_KEY.SESSIONID_TOKEN
              ),
            },
          }
        )
        .then(async (response) => {
          if (response.data.data.configureUserMfa.isSuccess) {
            var origin = btoa(window.location.href);
            if (window.location.href.indexOf("?source=") > 0) {
              var url = window.location.href.split("?", 1);
              origin = btoa(url[0]);
            }
            var paramValue =
              user.sub +
              "_" +
              props.requirement.claimId +
              "_" +
              props.requirement.id +
              "_" +
              props.memberNumber;
            const redirectUrl = `${micrositeBaseUrl}/#/${micrositeLoginRoute}?info=${paramValue}&origin=${origin}`;

            redirect(redirectUrl);
          } else {
            console.error("failed to send MFA sms");
            navigate("/error");
          }
        });
    }
  };

  const getIcon = (status: string | undefined) => {
    if (
      status === FILE_UPLOAD_STATUS.SUCCESS ||
      status === FILE_UPLOAD_STATUS.SANITISED
    ) {
      return (
        <GelIcon
          name="Attachment"
          width={getGelTokens().global.sizeBaseX6}
          height={getGelTokens().global.sizeBaseX6}
        />
      );
    } else if (
      status === FILE_UPLOAD_STATUS.VULNERABLE ||
      status === FILE_UPLOAD_STATUS.FAILED ||
      status === FILE_UPLOAD_STATUS.NOT_SUPPORTED ||
      status === FILE_UPLOAD_STATUS.FILE_SIZE_EX
    ) {
      return (
        <GelIcon
          name="AlertCircle"
          width={getGelTokens().global.sizeBaseX6}
          height={getGelTokens().global.sizeBaseX6}
          color={getGelTokens().global.themeColorIconDanger}
        />
      );
    } else {
      return <GelSpinner small overlay={false} />;
    }
  };
  const buttonText = TFN_DETAILS_REQUIREMENTS.includes(
    props.requirement.description
  )
    ? props.contentFullData?.tfnButtontext
    : props.contentFullData?.eftButtonText;
  const redirect = (redirectUrl) => {
    window.location.replace(redirectUrl);
  };
  return (
    <GelForm labelAtTop={true} onSubmit={onSubmit}>
      <GelFormField width="auto">
        <GelButton
          style={{
            width: "100%",
            textTransform: "unset",
          }}
          name=""
          primary
          large
          submit
        >
          {buttonText}
        </GelButton>

        {files.map((file, index) => (
          <GelRow
            style={{
              paddingLeft:
                files?.length === 1
                  ? getGelTokens().global.sizeBaseX4
                  : getGelTokens().global.sizeBaseX16,
              paddingBottom: getGelTokens().global.sizeBaseX2,
              paddingTop: getGelTokens().global.sizeBaseX2,
            }}
            key={Math.random()}
          >
            <GelCol
              style={{
                maxWidth: "3%",
                textAlign: "left",
              }}
            >
              <GelRow style={{ paddingTop: getGelTokens().global.sizeBaseX2 }}>
                <GelIcon
                  name="Attachment"
                  width={getGelTokens().global.sizeBaseX6}
                  height={getGelTokens().global.sizeBaseX6}
                />
              </GelRow>
            </GelCol>
            <GelCol>
              <GelRow>
                <GelCol
                  style={{
                    flex: 9,
                    paddingLeft: 0,
                    paddingTop: "10px",
                  }}
                >
                  <DocumentDownload
                    documentIdentifier={file.sharePointDocumentIdentifier}
                    displayName={file.name}
                  ></DocumentDownload>
                </GelCol>
              </GelRow>
            </GelCol>
          </GelRow>
        ))}
      </GelFormField>
    </GelForm>
  );
};

export default OtherRequirement;
