import { GelThemeProvider, useThemeSelector } from '@tal-gel/theming';
import { GelScreenDetector } from '@tal-gel/components';
import React, { useContext } from 'react';
import { key as tal } from '@tal-gel/theming-plugin-tal';
import { key as aware } from '@tal-gel/theming-plugin-aware';
import { key as vicSuper } from '@tal-gel/theming-plugin-vicsuper';
import { key as cbus } from '@tal-gel/theming-plugin-cbus';
import { key as ausSuper } from '@tal-gel/theming-plugin-aussuper';
import { key as amp } from '@tal-gel/theming-plugin-amp';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { ThemeContext } from './common/usercontext/themes.context';
import { HOST_BRAND_MAP } from './constants/constants';
import SESSION_STORAGE_KEY from './constants/storage.constant';

const GelThemes = () => {

  const { themeData } = useContext(ThemeContext)

  function getAppTheme() { 
    
    let sessionTheme = window.sessionStorage.getItem(SESSION_STORAGE_KEY.THEME);

    return sessionTheme ? HOST_BRAND_MAP[sessionTheme] : 
      (themeData?.theme ? HOST_BRAND_MAP[themeData.theme] : "tal")

   } 

    return (
        <GelThemeProvider theme={getAppTheme()}>
          <GelScreenDetector>
            <HashRouter>
              <App />
            </HashRouter>
          </GelScreenDetector>
        </GelThemeProvider>
    );
}

export default GelThemes;