import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    GelModal,
    GelParagraph,
    GelBoxLayout,
    GelRowLayout,
    GelButton,
    GelForm,
    GelSpinner
} from '@tal-gel/components';
import axios, { HttpStatusCode } from 'axios';
import { API } from '../../constants/constants';
import { GET_REFRESH_SESSION_QUERY } from '../../graphql/queries/graphql-queries.constant';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../common/usercontext/user.context';
import AuthenticationService from '../../common/authentication/auth.service';
import { GET_ERROR_PAGES_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { getGelTokens } from '@tal-gel/theming';

const TimeOutModal = () => {
    const [openModal, setOpenModal] = useState(true);
    const now = new Date();
    const targetDate = new Date(now.getTime() + 4 * 60 * 1000);
    const [ssoCookies, setSsoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState<number>(getTimeLeft(targetDate));
    const { setUserContext } = useContext(UserContext)
    const sessionWarningTimer = useRef<NodeJS.Timeout | null>(null);
    const { isStandAloneAuthenticated, clearSession } = AuthenticationService();
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | {
        mmcErrorPagesCollection: any;
    }>(null);
    
    const onCloseModal = () => {
        refreshToken();
        setOpenModal(false);
        stopTimer();
    }

    const refreshToken = () => {

        axios.post(API.BASEURL, {
            query: GET_REFRESH_SESSION_QUERY,
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
    }

    useEffect(() => {
        axios
          .post(API.CONTENTFUL_URL, {
            query: GET_ERROR_PAGES_CONTENT_QUERY,
            variables: {
              fundName: ssoCookies.tpid,
            },
          })
          .then(async (response) => {
            setcontentDataLoaded(true);
            setcontenfulData(response.data?.data);
          });
          
        sessionWarningTimer.current = setInterval(() => {
            const remainingTime = getTimeLeft(targetDate);
            setTimeLeft(remainingTime);

            if (remainingTime <= 0) {
                stopTimer();
                setOpenModal(false);
                clearSession();
                navigate("/error", { state: { errorCode: HttpStatusCode.Forbidden } });
            }

        }, 1000);

        return () => {
            if (sessionWarningTimer.current) {
                clearInterval(sessionWarningTimer.current);
            }
        };
    }, [ssoCookies]);

    const stopTimer = () => {
        if (sessionWarningTimer.current) {
            clearInterval(sessionWarningTimer.current);
            sessionWarningTimer.current = null;
        }
    };

    function getTimeLeft(targetDate: Date): number {
        const now = new Date();
        const timeDifference = targetDate.getTime() - now.getTime();
        return Math.max(timeDifference, 0);
    }

    const seconds = Math.floor((timeLeft / 1000) % 60);
    const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);

    if (!contentDataLoaded)
        return (<GelSpinner medium overlay />)

    return (
        <GelModal
            width="sm"
            height={isStandAloneAuthenticated ? getGelTokens().global.sizeBaseUnit * 50: getGelTokens().global.sizeBaseUnit * 55}
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeOnBackdropClick={false}
            sticky
            title={contentfulData?.mmcErrorPagesCollection?.items[0].timeOutModalHeader}
            action={ 
                <GelBoxLayout gap="small" space="auto">
                  {isStandAloneAuthenticated && <GelButton 
                    secondary medium 
                    width={getGelTokens().global.sizeBaseUnit * 22} 
                    onClick={clearSession}
                    style={{
                        width: getGelTokens().global.sizeBaseUnit * 30,
                    }}
                  >
                     {contentfulData?.mmcErrorPagesCollection?.items[0].logOutButtonText}
                  </GelButton>}
                  <GelButton 
                    primary medium 
                    width={getGelTokens().global.sizeBaseUnit * 22} 
                    onClick={onCloseModal}
                    style={{
                        width: getGelTokens().global.sizeBaseUnit * 40,
                    }}
                  >
                    {contentfulData?.mmcErrorPagesCollection?.items[0].stayLoggedInButtonText}
                  </GelButton>
                </GelBoxLayout>
              } >
            <GelRowLayout gutter="small">
                {(minutes > 0) &&
                    <GelParagraph>
                        {contentfulData?.mmcErrorPagesCollection?.items[0].sessionTimeOutWarning} {` ${minutes} minutes`}.
                    </GelParagraph>
                }
                {(minutes <= 0) &&
                    <GelParagraph>
                        {contentfulData?.mmcErrorPagesCollection?.items[0].sessionTimeOutWarning}  {` ${seconds} seconds`}.
                    </GelParagraph>
                }
                {!isStandAloneAuthenticated &&
                <GelParagraph>
                    {contentfulData?.mmcErrorPagesCollection?.items[0].sessionTimeOutLogOutText}.
                </GelParagraph>
                }
               

            </GelRowLayout>
        </GelModal>
    );
}

export default TimeOutModal;
