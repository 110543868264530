import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelRowLayout,
  GelIcon,
  GelLabel,
  GelLink,
  GelHeading3,
  GelParagraph,
  GelSpinner,
  GelList,
  GelListItem
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { TRANSACTION_STATUS } from '../../common/authentication/auth.utils';
import { useNavigate } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { API } from '../../constants/constants';
import { GET_ACTIVATEPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import axios from 'axios';
import FundBanner from '../common/fundBanner';

const CreatePassword = (props) => {
  const navigate = useNavigate();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const [loginStatusMessage, setloginStatusMessage] = useState("");
  const { setUserContext } = useContext(UserContext);
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcActivatePageCollection: any }>(null);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  let restPasswordStateToken = "";

  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    password: '',
    confirmPassword: ''
  });

  const { contextData } = useContext(UserContext)

  useEffect(() => {
    restPasswordStateToken = contextData.memberDetails?.stateToken!;

    axios.post(API.CONTENTFUL_URL, {
      query: GET_ACTIVATEPAGE_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });

  }, []);

  const onSubmit = () => {
    AccountApi.resetPassword(formData.password, contextData.memberDetails?.stateToken!)
      .then(response => response.json())
      .then(data => {
        const { status } = data;
        if (status.toString() === TRANSACTION_STATUS.MFA_REQUIRED) {
          let mfaStateToken = data.stateToken;
          console.log('Valid credentials, MFA Required');
          //User has been enrolled in a sms factor, retreive factorId and send MFA
          const factor = data._embedded.factors.find(f => f.factorType === 'sms');
          const factorId = factor?.id;
          if (!factor) {
            console.error(`User not enrolled in sms factor`);
          }
          // Extract members phone number from sms factor
          const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
          setUserContext({ memberDetails: { factorId: factorId, stateToken: mfaStateToken, mobileNumber: phoneNumber, oktaEmail: contextData.memberDetails?.oktaEmail }, claims: [] });

          if (phoneNumber)
            navigate("/confirmMobile");
          else
            navigate("/confirmMember");
        }
        else {
          //Unrecognised state, after successfully resetting password user should be prompted for MFA
          //this.setState({errorMessage: 'EnrollSmsFactorRequestNotAllowed'});
        }
      }).catch(httpError => {
        console.error('reset password failed errorMessage:', httpError);
        setloginStatusMessage("Create password failed");
      });
  };

  const handlePasswordInput = (event) => {
    // Check if passwords match    
    if (formData.confirmPassword) {
      setPasswordsMatch(formData.confirmPassword == event.target.value)
    }
  };

  const handleConfirmPasswordInput = (event) => {
    // Check if passwords match
    setPasswordsMatch(formData.password && formData.password == event.target.value);
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  if (!contentDataLoaded)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: getGelTokens().global.sizeNone,
      paddingLeft: getGelTokens().global.sizeNone,
      paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
      paddingTop: getGelTokens().global.sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none" >
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : getGelTokens().global.sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: getGelTokens().global.sizeBaseUnit * 50
          }}
          fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: getGelTokens().global.sizeBaseUnit * 16,
            paddingLeft: getGelTokens().global.sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
            <GelHeading3>
              {contentfulData?.mmcActivatePageCollection?.items[0]?.headerText}
            </GelHeading3>
            <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2 }}>{contentfulData?.mmcActivatePageCollection.items[0]?.subText}</GelParagraph>

            <GelContainerLite style={{ backgroundColor: "#D5F0F0", padding: "16px" }}>
              <GelLabel >{contentfulData?.mmcActivatePageCollection.items[0]?.passwordRequirementsHeader}</GelLabel>
              <GelParagraph>{contentfulData?.mmcActivatePageCollection.items[0]?.passwordRequirementsSubtext}</GelParagraph>
              <GelList style={{ paddingTop: "10px" }}>
                {contentfulData?.mmcActivatePageCollection.items[0]?.passwordRequirementsList.map((req) => (
                  <GelListItem>{req}</GelListItem>
                ))}
              </GelList>
            </GelContainerLite>

            <GelFormField
              label={contentfulData?.mmcActivatePageCollection.items[0]?.passwordText}
            >
              <GelPasswordInput
                name="password"
                value={formData.password}
                onChange={onFormDataChange}
                onInput={handlePasswordInput}
                required
                requiredErrorMsg={contentfulData?.mmcActivatePageCollection.items[0]?.errorMessageContent.passwordRequiredMsg}
                errorMsg={{}}
              />
            </GelFormField>
            <GelFormField
              label={contentfulData?.mmcActivatePageCollection.items[0]?.confirmPasswordText}
            >
              <GelPasswordInput
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={onFormDataChange}
                onInput={handleConfirmPasswordInput}
                required
                errorMsg={{
                  required: contentfulData?.mmcActivatePageCollection.items[0]?.errorMessageContent.confirmPasswordReqMsg
                }}
              />

              <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDanger, fontSize: getGelTokens().global.sizeBaseX3, paddingTop: getGelTokens().global.sizeBaseUnit }}>
                {!passwordsMatch && contentfulData?.mmcActivatePageCollection.items[0]?.errorMessageContent.passwordMatchMsg}
              </GelParagraph>

            </GelFormField>

            <GelButton
              name="" style={{
                width: "100%"
              }}
              primary large submit
            >
              {contentfulData?.mmcActivatePageCollection.items[0]?.activateButtonText}
            </GelButton>

            <GelBoxLayout space="auto" >
              {loginStatusMessage &&
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}><GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                  {contentfulData?.mmcActivatePageCollection.items[0]?.errorMessageContent.passwordReqMsg}
                </GelLabel>}
            </GelBoxLayout>

            <GelLabel style={{
              fontWeight: 100,
              paddingRight: getGelTokens().global.sizeBaseX2
            }}>{contentfulData?.mmcActivatePageCollection?.items[0]?.signInText}
              <GelLink onClick={navigateToLogin}> {contentfulData?.mmcActivatePageCollection?.items[0]?.signInLink}</GelLink>
            </GelLabel>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner></FundBanner>}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default CreatePassword;