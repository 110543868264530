import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { API, ReCaptchaSettings } from '../../constants/constants';
import { GET_FORGOTPASSWORD_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { GET_SUPERFUND_DATA_QUERY } from '../../graphql/queries/graphql-queries.constant';
import {
    GelForm, GelFormField,
    GelIcon,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelContainerLite,
    GelLabel,
    GelLink,
    GelSelect,
    GelRowLayout,
    GelEmailInput,
    GelReCaptcha,
    GelSpinner,
    GelHeading3,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router';
import FundBanner from '../common/fundBanner';
import { PASSWORD_RESET_MAIL } from '../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { useCookies } from 'react-cookie';

const ForgotPassword = () => {

    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcForgotPasswordPageCollection: any }>(null);
    const [superfundId, setSuperFund] = useState(null);
    const [superFundLoaded, setsuperFundLoaded] = useState(false);
    const [superFunds, setsuperFunds] = useState<null | { superFundList: any }>(null);
    const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const navigate = useNavigate();
    const [resetStatusMessage, setresetStatusMessage] = useState("");
    const [resetSuccessMessage, setresetSuccessMessage] = useState("");
    const [resetInProgress, setresetInProgress] = useState(false);
    const [status, setStatus] = useCookies(['forgotPwdStatus']);

    let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled == "1";

    const {
        formData,
        onFormDataChange,
        onFormDataReset,
        resetForm
    } = useGelFormData({
        reCaptchaToken: null,
        superFund: '',
        email: ''
    });

    const onSubmit = () => {

        window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");

        let requestData = {
            email: formData.email,
            superFundId: superfundId
        };

        setresetInProgress(true);
        setresetStatusMessage("");

        axios.post(API.BASEURL, {
            query: PASSWORD_RESET_MAIL,
            variables: {
                forgotPasswordRequest: requestData
            }
        },
            {
                headers: {
                    're-captcha': formData.reCaptchaToken
                }
            })
            .then(async (response) => {
                setresetInProgress(false);
                if (response.data.data.sendPasswordResetMail) {
                    setStatus('forgotPwdStatus', contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.resetPasswordSuccessMsg);
                    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "successReload");
                    navigate(0);
                }
                else {
                    setStatus('forgotPwdStatus', contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.resetFailedMsg);
                    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "errorReload");
                    navigate(0);
                }
            })
    };

    const navigateToSignIn = () => {
        window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "");
        setStatus('forgotPwdStatus', "");
        navigate("/login");
    };

    const onSuperFundChange = event => {
        setSuperFund(event.target.value);
    }


    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_FORGOTPASSWORD_CONTENT_QUERY,
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });

        axios.post(API.BASEURL, {
            query: GET_SUPERFUND_DATA_QUERY,
        })
            .then(async (response) => {
                setsuperFundLoaded(true);
                setsuperFunds(response.data?.data);
            });

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD) == "successReload") {
            setresetSuccessMessage(status.forgotPwdStatus);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");
        }

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD) == "errorReload") {
            setresetStatusMessage(status.forgotPwdStatus);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");
        }

    }, []);

    if (!contentDataLoaded || !superFundLoaded)
        return <GelSpinner medium overlay />

    return (
        <GelContainerLite style={{
            paddingRight: getGelTokens().global.sizeNone,
            paddingLeft: getGelTokens().global.sizeNone,
            paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
            paddingTop: getGelTokens().global.sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : getGelTokens().global.sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: getGelTokens().global.sizeBaseUnit * 50
                    }}
                    fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    parseResponseBody={false}
                    style={{ 
                        paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
                        paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }}>
                    <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
                        <GelHeading3>
                            {contentfulData?.mmcForgotPasswordPageCollection.items[0]?.headerText}
                        </GelHeading3>
                        <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 2 }}>
                            {contentfulData?.mmcForgotPasswordPageCollection.items[0]?.subText}
                        </GelParagraph>

                        <GelFormField
                            label={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.superFundText}
                        >
                            <GelSelect
                                name="superfund"
                                onChange={onSuperFundChange}
                                placeholder="Please select"
                                options={superFunds?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                                    { label: superFund.superfundName, value: superFund.superfundId }
                                ))}
                                required
                                requiredErrorMsg={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.superFundReqMsg}
                            />
                        </GelFormField>
                        <GelFormField label={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.emailText} >
                            <GelEmailInput
                                name="email"
                                onChange={onFormDataChange}
                                required
                                value={formData.email}
                                requiredErrorMsg={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.emailReqMsg}
                                formatErrorMsg={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.emailFormatMsg}
                            />
                        </GelFormField>
                        {isCaptchaEnabled &&
                            <GelFormField
                                label={contentfulData?.mmcForgotPasswordPageCollection.items[0]?.captchaText}>
                                <GelReCaptcha
                                    name="reCaptchaToken"
                                    sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                                    onChange={onFormDataChange}
                                    errorMsg={{
                                        required: `${contentfulData?.mmcForgotPasswordPageCollection.items[0]?.errorMessageContent.captchaReqMsg}`,
                                    }}
                                />
                            </GelFormField>}

                        {resetStatusMessage &&
                            <GelBoxLayout space={[1, 8]}>
                                <GelIcon color={getGelTokens().global.themeColorIconDanger} name="AlertCircle" inline />
                                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                                    {resetStatusMessage}
                                </GelLabel>
                            </GelBoxLayout>
                        }
                        {resetSuccessMessage &&
                            <GelBoxLayout space={[1, 8]}>
                                <GelIcon color={getGelTokens().global.themeColorIconSuccess} name="CheckSmall" inline />
                                <GelLabel style={{ color: getGelTokens().global.themeColorTextSuccess }}>
                                    {resetSuccessMessage}
                                </GelLabel>
                            </GelBoxLayout>
                        }
                        {!resetInProgress &&
                            <GelFormField width="auto" style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 3 }}>
                                <GelButton
                                    name="" style={{
                                        width: "100%",
                                    }}
                                    primary large submit
                                >
                                    {contentfulData?.mmcForgotPasswordPageCollection.items[0]?.continueButtonText}
                                </GelButton>
                            </GelFormField>}
                        {resetInProgress &&
                            <GelSpinner medium overlay={true} />}
                        <GelLabel style={{
                            fontWeight: 100,
                            paddingRight: getGelTokens().global.sizeBaseX2
                        }}> {contentfulData?.mmcForgotPasswordPageCollection.items[0]?.signInText}
                            <GelLink onClick={navigateToSignIn}>  {contentfulData?.mmcForgotPasswordPageCollection.items[0]?.signInLink}</GelLink>
                        </GelLabel>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner></FundBanner>}
            </GelBoxLayout>
        </GelContainerLite>
    );
};

export default ForgotPassword;