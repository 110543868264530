import React, { useContext } from "react";
import {
    GelRowLayout,
    GelParagraph,
    GelHeading3,
    GelHeading5,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { AdodeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickEvents, AdobePageName } from "../../constants/constants";
import { useCookies } from "react-cookie";
const SupportInRecovery = (props) => {
    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [ssoCookies] = useCookies(["tpid"]);

    const ImageContainer = styled.div(() => ({
        position: "relative", textAlign: "center", backgroundSize: "cover",
        backgroundRepeat: "no-repeat", height: "168px", width: "100%", backgroundPosition: "top", borderRadius: getGelTokens().global.sizeBaseX2
    }));
    
    const onLinkClick = (linkText) => {
        AdodeAnalytics.PushCardClickEvent(ssoCookies.tpid, AdobeClickEvents.CardClick, AdobePageName.HealthSupport, 
            props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportInRecoveryHeader,
            linkText);
    }
    return (
        <GelContainerLite style={{ padding: `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeNone}px` }}>
            <GelRowLayout style={{ paddingBottom: getGelTokens().global.sizeBaseX9 }}>
                <GelHeading3>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportInRecoveryHeader}</GelHeading3>
            </GelRowLayout>

            <GelColumnLayout distribution={!isLargerThanLgScreen(screen) ? "1" : "1/2 1/2"}>

                <GelContainerLite style={{ padding: "initial"}}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText)}>
                        <ImageContainer id="supportService" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.url})` }}>
                            
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>
                    <GelParagraph style={{ padding: `${getGelTokens().global.sizeBaseX4}px ${getGelTokens().global.sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesSubtext}</GelParagraph>

                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText}</GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial"}}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText)}>
                        <ImageContainer id="supportGuide" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.url})` }}>                            
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>
                    <GelParagraph style={{ padding: `${getGelTokens().global.sizeBaseX4}px ${getGelTokens().global.sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesSubtext}</GelParagraph>

                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText}</GelLink>
                </GelContainerLite>

            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default SupportInRecovery;