import React, { useContext } from 'react';
import {
    GelBoxLayout,
    GelIcon,
    GelParagraph,
    GelTag
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../../common/usercontext/user.context';

const DigiCorroBadge = () => {
    const { contextData, setUserContext } = useContext(UserContext);

    if (contextData.memberDetails?.unReadDigiCorroMessageCount == 0) {
        return null;
    }

    return (
        <span style={{ 
            marginRight: `${getGelTokens().global.sizeBaseUnit * 2}px`, 
            paddingBottom: `${getGelTokens().global.sizeBaseUnit}px`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
             }}>
            <GelTag small warning>
                <GelBoxLayout
                    distribution='center'
                    alignment='center'
                    space={[0.5, 10]}
                    style={{
                        gap: `${getGelTokens().global.sizeBaseUnit}px`,
                        paddingLeft: `${getGelTokens().global.sizeBaseX2}px`,
                        paddingRight: `${getGelTokens().global.sizeBaseX2}px`,
                        cursor: 'pointer'
                    }}
                    childStyle={{
                        paddingLeft: `${getGelTokens().global.sizeNone}px`,
                        paddingRight: `${getGelTokens().global.sizeNone}px`
                    }}>
                    <GelIcon
                        name="Notification"
                        width={getGelTokens().global.sizeBaseUnit * 4}
                        color={getGelTokens().tags.tagWarningIconColor}
                    />
                    <GelParagraph style={{ fontSize: getGelTokens().global.sizeBaseX3, paddingRight: getGelTokens().global.sizeBaseUnit, color: getGelTokens().global.themeColorTextDark }}>{contextData.memberDetails?.unReadDigiCorroMessageCount}</GelParagraph>
                </GelBoxLayout>
            </GelTag>
        </span>
    );

}

export default DigiCorroBadge;