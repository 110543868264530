import styled from "@emotion/styled";
import React, { CSSProperties } from "react";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: any;
  display?: CSSProperties["display"];
  alignContent?: CSSProperties["alignContent"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  background?: CSSProperties["background"];
  columnGap?: CSSProperties["columnGap"];
  flexBasis?: CSSProperties["flexBasis"];
  flexDirection?: CSSProperties["flexDirection"];
  flexGrow?: CSSProperties["flexGrow"];
  flexShrink?: CSSProperties["flexShrink"];
  flexWrap?: CSSProperties["flexWrap"];
  gap?: CSSProperties["gap"];
  height?: CSSProperties["height"];
  justifyContent?: CSSProperties["justifyContent"];
  justifyItems?: CSSProperties["justifyItems"];
  justifySelf?: CSSProperties["justifySelf"];
  margin?: CSSProperties["margin"];
  marginBottom?: CSSProperties["marginBottom"];
  marginTop?: CSSProperties["marginTop"];
  marginRight?: CSSProperties["marginRight"];
  marginLeft?: CSSProperties["marginLeft"];
  order?: CSSProperties["order"];
  padding?: CSSProperties["padding"];
  paddingBottom?: CSSProperties["paddingBottom"];
  paddingTop?: CSSProperties["paddingTop"];
  paddingRight?: CSSProperties["paddingRight"];
  paddingLeft?: CSSProperties["paddingLeft"];
  rowGap?: CSSProperties["rowGap"];
  width?: CSSProperties["width"];
  minWidth?: CSSProperties["minWidth"];
  maxWidth?: CSSProperties["maxWidth"];
  children?: React.ReactNode;
} & React.ComponentProps<"div">;

// export const Flexbox = (props: Props) => {
//   return <Wrapper {...props} />;
// };

const Flexbox = styled.div<Props>(
  ({
    as,
    display = "flex",
    alignContent,
    alignItems,
    alignSelf,
    background,
    columnGap,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    gap,
    height,
    justifyContent,
    justifyItems,
    justifySelf,
    margin,
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    order,
    padding,
    paddingBottom,
    paddingTop,
    paddingRight,
    paddingLeft,
    rowGap,
    width,
    minWidth,
    maxWidth,
  }) => ({
    as,
    display,
    alignContent,
    alignItems,
    alignSelf,
    background,
    columnGap,
    flexBasis,
    flexDirection,
    flexGrow,
    flexShrink,
    flexWrap,
    gap,
    height,
    justifyContent,
    justifyItems,
    justifySelf,
    margin,
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    order,
    padding,
    paddingBottom,
    paddingTop,
    paddingRight,
    paddingLeft,
    rowGap,
    width,
    minWidth,
    maxWidth,
  }),
);

export default Flexbox;
