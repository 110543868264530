import {
    GelLabel,
    GelParagraph,
    GelButton,
    GelIcon,
    GelModal,
    GelContainerLite,
    GelCollapsible,
    GelBoxLayout,
    GelRowLayout,
    GelTag,
    GelScreenDetectorContext
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useContext, useEffect, useState } from "react";
import Uploader from "./uploader";
import FileTypes from "./fileTypes";
import React from "react";
import { AdobeClickEvents, AdobePageName, PAYMENT_DETAILS_REQUIREMENTS, TFN_DETAILS_REQUIREMENTS } from "../../../constants/constants";
import OtherRequirement from "./otherRequirement";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { AdodeAnalytics } from "../../../common/analytics/adobe-analytics";

interface RequirementDocumentsProps {
    requirement: any;
    contentFullData: any;
    memberNumber: string;
    superfundId: number;
}

const RequirementDocuments = (props: RequirementDocumentsProps) => {
    let requirementData = props.requirement;
    const [ssoCookies] = useCookies(['tpid']);
    const [openModal, setOpenModal] = useState(false);
    const [showRequirementDetails, setShowRequirementDetails] = useState(false);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const onHeaderClick = () => {

        if (allowSectionCollapse)
            setShowRequirementDetails(!showRequirementDetails);

        AdodeAnalytics.PushAccordionClickEvent(ssoCookies.tpid, AdobeClickEvents.AccordionClick, AdobePageName.ClaimDetails, 
            props.requirement.friendlyText);
    }
    const location = useLocation();

    useEffect(() => {
        var selectedNotification = location.state?.selectedNotification;
        setShowRequirementDetails(selectedNotification == requirementData.id);
    }, []);

    let allowSectionCollapse = true;
    const onDisableCollapse = (allowCollapse: boolean) => {
        allowSectionCollapse = allowCollapse;
    }

    return (
        <div id={requirementData.id}>
            <GelContainerLite style={{
                borderTop: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>
                <GelBoxLayout space={!isXsScreen(screen) ? [7, 0.5, 0.04] : [1, 0.01, 0.05]} gutter={getGelTokens().global.sizeBaseUnit}
                 style={{
                    borderBottom: showRequirementDetails ? `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}` : "",
                    width: "auto",
                    padding: getGelTokens().global.sizeBaseUnit * 4,
                    marginLeft: -getGelTokens().global.sizeBaseUnit * 4,
                    marginRight: -getGelTokens().global.sizeBaseUnit * 4
                }}>

                    <GelLabel
                        style={{
                            fontFamily: getGelTokens().global.fontFamilySans,
                            fontSize: getGelTokens().global.sizeBaseUnit * 4,
                            fontWeight: getGelTokens().global.fontWeightRegular
                        }}>
                        {props.requirement.friendlyText}
                    </GelLabel>


                    {(props.requirement.receivedDate || props.requirement.associatedDocuments.length) &&
                        <GelTag small warning style={{ height: "auto" }}>
                            <GelBoxLayout distribution='center' alignment='center' space={[1,20]}
                                style={{                                    
                                    paddingLeft: `${getGelTokens().global.sizeBaseUnit}px`, 
                                    paddingRight: `${getGelTokens().global.sizeBaseX2}px`
                                }}
                                childStyle={{
                                    marginLeft:`-${getGelTokens().global.sizeBaseUnit}px`,                                   
                                    paddingRight: `${getGelTokens().global.sizeNone}px`
                                }}>
                                <GelIcon
                                    name="Circle"
                                    width={getGelTokens().global.sizeBaseUnit * 2}
                                    color={getGelTokens().global.themeColorBorderWarning}></GelIcon>
                                <GelParagraph style={{ fontSize: getGelTokens().global.sizeBaseX3, color:getGelTokens().global.themeColorTextDark }}>{props.contentFullData?.requirementInReview}</GelParagraph>
                            </GelBoxLayout>
                        </GelTag>
                    }


                    {!props.requirement.receivedDate && !props.requirement.associatedDocuments.length &&
                        <GelTag small danger style={{ height: "auto" }}>
                            <GelBoxLayout distribution='center' alignment='center' space={[0.5, 1]}
                                style={{                                    
                                    paddingLeft: `${getGelTokens().global.sizeBaseUnit}px`, 
                                    paddingRight: `${getGelTokens().global.sizeBaseX2}px`
                                }}
                                childStyle={{
                                    marginLeft:`-${getGelTokens().global.sizeBaseUnit}px`,                                   
                                    paddingRight: `${getGelTokens().global.sizeNone}px`
                                }}>
                                <GelIcon
                                    name="Circle"
                                    width={getGelTokens().global.sizeBaseUnit * 2}
                                    color={getGelTokens().global.themeColorBorderDanger}></GelIcon>
                                <GelParagraph style={{ fontSize: getGelTokens().global.sizeBaseX3, color:getGelTokens().global.themeColorTextDark }}>{props.contentFullData?.requirementRequired}</GelParagraph>
                            </GelBoxLayout>
                        </GelTag>
                    }


                    <GelIcon name={showRequirementDetails ? "ChevronUp" : "ChevronDown"} color={getGelTokens().global.themeColorIconDefault} width="20px" height="20px"></GelIcon>
                </GelBoxLayout>

            </GelContainerLite>
            <GelContainerLite style={{
                borderBottom: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                width: "auto"
            }}>
                <GelCollapsible open={showRequirementDetails} >
                    <GelContainerLite style={{ paddingBottom: getGelTokens().global.sizeBaseUnit * 2 }}>
                        {!PAYMENT_DETAILS_REQUIREMENTS.includes(props.requirement.description.toUpperCase()) &&
                            !TFN_DETAILS_REQUIREMENTS.includes(props.requirement.description) &&
                            <div>
                                <GelRowLayout gutter="xsmall" style={{
                                    paddingTop: getGelTokens().global.sizeBaseUnit,
                                    paddingBottom: getGelTokens().global.sizeBaseUnit,
                                }}
                                >
                                    <GelLabel > {props.contentFullData?.documentsToStart}
                                    </GelLabel>
                                    <GelParagraph style={{ paddingRight: "10px" }}>
                                        {props.contentFullData?.maxFileSize}
                                    </GelParagraph>
                                    <GelButton tertiary small onClick={() => setOpenModal(true)}>
                                        {props.contentFullData?.viewSupportedTypes}
                                    </GelButton>
                                    <GelModal
                                        width="sm"
                                        height={getGelTokens().global.sizeBaseUnit * 80}
                                        open={openModal}
                                        onClose={() => setOpenModal(false)}
                                        sticky
                                        title={props.contentFullData?.viewSupportedTypes}
                                    >
                                        <FileTypes></FileTypes>
                                    </GelModal>
                                </GelRowLayout>

                                <Uploader
                                    requirement={requirementData}
                                    contentFullData={props.contentFullData}
                                    setAllowCollapse={onDisableCollapse}
                                />
                            </div>
                        }
                        {PAYMENT_DETAILS_REQUIREMENTS.includes(props.requirement.description.toUpperCase()) &&
                            <div style={{
                                paddingTop: getGelTokens().global.sizeBaseUnit * 2,
                                paddingBottom: getGelTokens().global.sizeBaseUnit * 2,
                                marginLeft: -getGelTokens().global.sizeBaseUnit * 4,
                                marginRight: -getGelTokens().global.sizeBaseUnit * 4
                            }}>
                                <OtherRequirement key={props.requirement.id}
                                    contentFullData={props.contentFullData}
                                    requirement={props.requirement}
                                    memberNumber={props.memberNumber}
                                    requirementData={requirementData}>
                                </OtherRequirement>
                            </div>
                        }
                        {TFN_DETAILS_REQUIREMENTS.includes(props.requirement.description) &&
                            <div style={{
                                paddingTop: getGelTokens().global.sizeBaseUnit * 2,
                                paddingBottom: getGelTokens().global.sizeBaseUnit * 2,
                                marginLeft: -getGelTokens().global.sizeBaseUnit * 4,
                                marginRight: -getGelTokens().global.sizeBaseUnit * 4
                            }}>
                                <OtherRequirement key={props.requirement.id}
                                    contentFullData={props.contentFullData}
                                    requirement={props.requirement}
                                    memberNumber={props.memberNumber}
                                    requirementData={requirementData}>
                                </OtherRequirement>
                            </div>
                        }
                    </GelContainerLite>
                </GelCollapsible>
            </GelContainerLite>
        </div>
    );
};
export default (RequirementDocuments);
