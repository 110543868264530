import React, { useContext, useEffect, useState } from 'react';
import { GET_RESETPASSWORD_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import {
    GelForm, GelFormField,
    GelPasswordInput,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelContainerLite,
    GelIcon,
    GelLabel,
    GelHeading3,
    GelParagraph,
    GelRowLayout,
    GelList,
    GelListItem,
    GelSpinner
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { TRANSACTION_STATUS } from '../../common/authentication/auth.utils';
import { useNavigate, useParams } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { API } from '../../constants/constants';
import axios from 'axios';
import FundBanner from '../common/fundBanner';

const ResetPassword = (props) => {
    const navigate = useNavigate();
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [recoveryTokenVerified, setrecoveryTokenVerified] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcPasswordResetPageCollection: any }>(null);
    const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [loginStatusMessage, setloginStatusMessage] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const { setUserContext } = useContext(UserContext);

    let { recoverytoken } = useParams();
    let restPasswordStateToken = "";
    let userId = "";

    const {
        formData,
        onFormDataChange,
        onFormDataReset,
        resetForm
    } = useGelFormData({
        email: ''
    });

    const { contextData } = useContext(UserContext)

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_RESETPASSWORD_CONTENT_QUERY,
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });

        if (recoverytoken) {
            AccountApi.verifyRecoveryToken(recoverytoken)
                .then(response => response.json())
                .then(data => {
                    console.log('verifyRecoveryToken data', data);
                    if (!data.errorCode) {
                        restPasswordStateToken = data.stateToken
                        userId = data._embedded.user.profile.login;
                        setUserContext({ memberDetails: { factorId: '', stateToken: restPasswordStateToken, mobileNumber: '', oktaEmail: userId }, claims: [] });
                        setrecoveryTokenVerified(true);
                    }
                    else {
                        console.log('verifyRecoveryToken data failed');

                    }
                })
                .catch(httpError => {
                    console.log('verifyRecoveryToken Failed. Please try again', httpError);
                    setloginStatusMessage("Failed verifyRecoveryToken. Please try again");
                });

        }
    }, []);

    const onSubmit = () => {
        AccountApi.resetPassword(formData.password, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
                const { status } = data;
                if (status.toString() === TRANSACTION_STATUS.MFA_REQUIRED) {
                    let mfaStateToken = data.stateToken;
                    console.log('Valid credentials, MFA Required');
                    //User has been enrolled in a sms factor, retreive factorId and send MFA
                    const factor = data._embedded.factors.find(f => f.factorType === 'sms');
                    const factorId = factor?.id;
                    if (!factor) {
                        console.error(`User not enrolled in sms factor`);
                    }
                    // Extract members phone number from sms factor
                    const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
                    if (phoneNumber) {
                        setUserContext({ memberDetails: { factorId: factorId, stateToken: mfaStateToken, mobileNumber: phoneNumber, oktaEmail: contextData.memberDetails?.oktaEmail }, claims: [] });
                        navigate("/confirmMobile");
                    }
                    else {
                        navigate("/confirmMember");
                    }
                }
                else {
                    //Unrecognised state, after successfully resetting password user should be prompted for MFA
                    //this.setState({errorMessage: 'EnrollSmsFactorRequestNotAllowed'});
                }
            }).catch(httpError => {
                console.error('reset password failed errorMessage:', httpError);
                setloginStatusMessage("reset password failed");
            });
    };

    const handlePasswordInput = (event) => {
        // Check if passwords match    
        if (formData.confirmPassword) {
            setPasswordsMatch(formData.confirmPassword == event.target.value)
        }
    };

    const handleConfirmPasswordInput = (event) => {
        // Check if passwords match
        setPasswordsMatch(formData.password && formData.password == event.target.value);
    };

    if (!contentDataLoaded || !recoveryTokenVerified) {
        return <GelSpinner medium overlay />
    }

    return (
        <GelContainerLite style={{
            paddingRight: getGelTokens().global.sizeNone,
            paddingLeft: getGelTokens().global.sizeNone,
            paddingBottom: getGelTokens().global.sizeBaseUnit * 15,
            paddingTop: getGelTokens().global.sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : getGelTokens().global.sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: getGelTokens().global.sizeBaseUnit * 50
                    }}
                    fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    reset={resetForm}
                    onReset={onFormDataReset}
                    parseResponseBody={false}
                    style={{ 
                        paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
                        paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }}>
                    <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
                        <GelHeading3 style={{ padding: "20px 0px" }}>
                            {contentfulData?.mmcPasswordResetPageCollection.items[0]?.headerText}
                        </GelHeading3>
                        <GelParagraph style={{ marginTop: -getGelTokens().global.sizeBaseUnit * 2 }}>{contentfulData?.mmcPasswordResetPageCollection.items[0]?.headerSubtext}</GelParagraph>
                        <GelContainerLite style={{ backgroundColor: "#D5F0F0", padding: "16px" }}>
                            <GelLabel >{contentfulData?.mmcPasswordResetPageCollection.items[0]?.passwordRequirementsHeader}</GelLabel>
                            <GelParagraph>{contentfulData?.mmcPasswordResetPageCollection.items[0]?.passwordRequirementsSubtext}</GelParagraph>
                            <GelList style={{ paddingTop: "10px" }}>
                                {contentfulData?.mmcPasswordResetPageCollection.items[0]?.passwordRequirementsList.map((req) => (
                                    <GelListItem>{req}</GelListItem>
                                ))}
                            </GelList>
                        </GelContainerLite>

                        <GelFormField style={{ padding: "" }}
                            label={contentfulData?.mmcPasswordResetPageCollection.items[0]?.newPassword}
                        >
                            <GelPasswordInput
                                name="password"
                                value={formData.password}
                                onChange={onFormDataChange}
                                onInput={handlePasswordInput}
                                required
                                requiredErrorMsg={contentfulData?.mmcPasswordResetPageCollection.items[0]?.errorMessageContent.passwordRequiredMsg}
                                errorMsg={{
                                }}
                            />
                        </GelFormField>
                        <GelFormField
                            label={contentfulData?.mmcPasswordResetPageCollection.items[0]?.confirmPassword}
                        >
                            <GelPasswordInput
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={onFormDataChange}
                                onInput={handleConfirmPasswordInput}
                                required
                                errorMsg={{
                                    required: contentfulData?.mmcPasswordResetPageCollection.items[0]?.errorMessageContent.passwordRequiredMsg
                                }}
                            />

                            <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDanger, fontSize: getGelTokens().global.sizeBaseX3, paddingTop: getGelTokens().global.sizeBaseUnit }}>
                                {!passwordsMatch && contentfulData?.mmcPasswordResetPageCollection.items[0]?.errorMessageContent.passwordMatchMsg}
                            </GelParagraph>
                        </GelFormField>
                        <GelButton
                            name="" style={{
                                width: "100%",
                            }}
                            primary large submit
                        >
                            {contentfulData?.mmcPasswordResetPageCollection.items[0]?.buttonText}
                        </GelButton>

                        <GelBoxLayout space="auto">
                            {loginStatusMessage &&
                                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}><GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                                    {contentfulData?.mmcPasswordResetPageCollection.items[0]?.errorMessageContent.passwordReqMsg}
                                </GelLabel>}
                        </GelBoxLayout>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner></FundBanner>}
            </GelBoxLayout>
        </GelContainerLite>
    )
};

export default ResetPassword;