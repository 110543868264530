import styled from "@emotion/styled";
import { getGelTokens } from "@tal-gel/theming";
import { GelParagraph, GelLabel, GelCaption } from "@tal-gel/components";

export const tokens = getGelTokens();

export const Link = styled.a<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  background: tokens.global.themeColorBackgroundDefault,
  border: '1px solid',
  borderColor: tokens.global.themeColorInteractive1,
  borderRadius: tokens.global.sizeBaseX2,
  padding: tokens.global.sizeBaseX4,
  display: "flex",
  textDecoration: "none",
  cursor: "pointer",

  ...(isDisabled
    ? {
        opacity: 0.6,
        pointerEvents: "none",
        cursor: "default",
        userSelect: "none",
      }
    : {}),

  "&:hover": {
    background: tokens.brand.brandColorPrimary1T70,
  },
  "&:focus": {
    background: tokens.brand.brandColorPrimary1T70,
    boxShadow: `0 0 0 2px ${tokens.global.themeColorWhite}, 0 0 0 4px ${tokens.global.themeColorAccent}`,
  },
  "&:active": {
    background: tokens.global.themeColorInteractive2Active,
    boxShadow: "none",
  },
}));

export const IconWrapper = styled.div({
  paddingLeft: tokens.global.sizeBaseX4,
});

export const Unread = styled.div({
  position: "relative",
  width: 12,
  height: 12,
  paddingTop: 14,
  marginRight: tokens.global.sizeBaseX4,
  "&:after": {
    content: '""',
    display: "block",
    width: 12,
    height: 12,
    borderRadius: "50%",
    background: tokens.global.themeColorAccent,
  },
});

export const HiddenText = styled.span({
  position: "absolute",
  width: 1,
  overflow: "hidden",
  clip: "rect(0, 0, 0, 0)",
  whiteSpace: "nowrap",
  clipPath: "inset(50%)",
});

export const baseStyles = {
  marginTop: 0,
  marginBottom: 0,
  color: tokens.global.themeColorTextDefault,
  fontWeight: '400',
  fontSize: '15px'
};

export const ReadTitle = styled(GelParagraph)({...baseStyles, fontWeight: '700'});

export const UnreadTitle = styled(GelLabel)({...baseStyles, fontWeight: '700'});

export const SubText = styled(GelLabel)({...baseStyles, fontSize: '12px'});

export const DateText = styled(GelLabel)({...baseStyles, fontSize: '14px'});
