import React, { useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { StyledListHeader } from "../../styles/nav.styles";
import { NavProps } from "./nav-props";
import { useLocation } from 'react-router-dom';


const MiniTopNavMob = ({ onMenuHideClick, textData, activeMenuItem}:NavProps) => {

    const[selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname == "/help")
            setSelectedMenuItem(null);

        setSelectedMenuItem(activeMenuItem)
    })
    return (
        <GelRowLayout >
                <GelBoxLayout>
                    <StyledListHeader>
                    <GelLabel style={{
                        color: getGelTokens().global.themeColorWhite,
                        fontWeight: getGelTokens().global.fontWeightBold
                    }}>{selectedMenuItem ? selectedMenuItem.text.toUpperCase() : "NEED HELP"}</GelLabel>
                        <GelIcon
                            name="MinimalDown"
                            width={getGelTokens().global.sizeBaseUnit * 6}
                            style={{ float: "right" }}
                            color={getGelTokens().global.themeColorWhite}
                            onClick={() => onMenuHideClick()}
                        />
                    </StyledListHeader>
                </GelBoxLayout>
            </GelRowLayout>
    );
};

export default MiniTopNavMob;