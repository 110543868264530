import React, { useContext } from "react";
import {
    GelParagraph,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext,
    GelHeading4
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useCookies } from "react-cookie";
import { AdodeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickEvents, AdobePageName } from "../../constants/constants";

const HealthConnectorCampaign = (props) => {
    const { screen, isLargerThanMdScreen, isXsScreen, isSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [ssoCookies] = useCookies(["tpid"]);

    const ImageContainer = styled.div(() => ({
        position: "relative", textAlign: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "100%",
        minHeight: isXsScreen(screen) | isSmScreen(screen) ? "278px" : "238px", width: "100%", backgroundPosition: "center"
    }));

    const Spacer = styled.div(() => ({
        paddingTop: getGelTokens().global.sizeBaseX6
    }));

    const onLinkClick = (linkText) => {
        AdodeAnalytics.PushCardClickEvent(ssoCookies.tpid, AdobeClickEvents.CardClick, AdobePageName.HealthSupport, 
            props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportLovedOnesHeader,
            linkText);
    }

    return (
        <GelContainerLite id="healthConnectorCampaignContainer" style={{ padding: getGelTokens().global.sizeNone }}>
            <GelColumnLayout verticalGutter={getGelTokens().global.sizeNone} gutter={getGelTokens().global.sizeNone} distribution={isXsScreen(screen) | isSmScreen(screen) ? "1" : "1/2 1/2"}>

                <ImageContainer id="supportService" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorCampaignImage.url})` }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorLink} target="_blank"
                    onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorLinkText)}>
                        <GelContainerLite style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "60px 40px" }}>
                            <GelHeading4 style={{ color: getGelTokens().global.themeColorBackgroundDefault, fontSize: isLargerThanMdScreen(screen) ? getGelTokens().global.fontSizeHeadingMedium : getGelTokens().global.fontSizeHeadingXSmall }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorCampaignImage.title}</GelHeading4>
                            <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault, fontSize: isLargerThanMdScreen(screen) ? getGelTokens().global.fontSizeBodyDefault : getGelTokens().global.fontSizeBodySmall }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorCampaignImage.description}</GelParagraph>
                        </GelContainerLite>
                    </GelLink>
                </ImageContainer>

                <GelContainerLite style={{
                    minHeight: isXsScreen(screen) | isSmScreen(screen) ? "278px" : "238px", backgroundColor: getGelTokens().global.themeColorBackgroundInverse,
                    padding: isLargerThanMdScreen(screen) ? `${getGelTokens().global.sizeBaseX2}% ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX2}% ${getGelTokens().global.sizeBaseX5}px`
                }}>
                    <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault }}>
                    {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorText}
                        <Spacer></Spacer>
                        <GelLink style={{ color: getGelTokens().global.themeColorBackgroundDefault }}
                            href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorLink}
                            target="_blank"
                            onClick = {() =>onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorLinkText)}>
                                {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorLinkText}</GelLink>
                    </GelParagraph>
                </GelContainerLite>

            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default HealthConnectorCampaign;