import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField, GelTextInput, GelColumnLayout,
  GelIcon,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelLabel,
  GelLink,
  GelSelect,
  GelRowLayout,
  GelStandardDateInput,
  GelReCaptcha,
  GelSpinner,
  GelHeading3,
  GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { API, ReCaptchaSettings } from '../../constants/constants';
import { GET_SIGNUPPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import FundBanner from '../common/fundBanner';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GET_SUPERFUND_DATA_QUERY } from '../../graphql/queries/graphql-queries.constant';
import { REGISTER_USER } from '../../graphql/queries/graphql-mutations';
import { UserRegistrationStatus } from '../../common/models/accountStatus.model';
import { useCookies } from 'react-cookie';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';

const SignUpPage = () => {
  const [superfundId, setSuperFund] = useState(null);
  const [superFundLoaded, setsuperFundLoaded] = useState(false);
  const [superFunds, setsuperFunds] = useState<null | { superFundList: any }>(null);
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcSignUpPageCollection: any }>(null);
  const navigate = useNavigate();
  const [registerStatusMessage, setregisterStatusMessage] = useState("");
  const [registerSuccessMessage, setregisterSuccessMessage] = useState("");
  const [status, setStatus] = useCookies(['signUpStatus']);
  const [registerInProgress, setregisterInProgress] = useState(false);
  let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled == "1";

  const {
    formData,
    onFormDataChange,
  } = useGelFormData({
    reCaptchaToken: null,
    superFund: '',
    memberNumber: '',
    dateOfBirth: ''
  });

  const onSubmit = () => {

    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP,"");

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: superfundId
    };

    setregisterInProgress(true);
    setregisterStatusMessage("");

    axios.post(API.BASEURL, {
      query: REGISTER_USER,
      variables: {
        registerUserRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setregisterInProgress(false);

        if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.MEMBER_NOT_FOUND){
          setStatus('signUpStatus',contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.memberNotFoundMsg);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP,"reload"); 
          navigate(0);
        }
        else if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ACTIVATION_EMAIL_SENT)
          setregisterSuccessMessage(contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.registerSuccessMsg);
        else if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ALREADY_ACTIVE ||
          response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ALREADY_EXISTS)
          setregisterStatusMessage(contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.userAlreadyActiveMsg);
        else
          setregisterStatusMessage(contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.registerFailedMsg);
      })
  };

  const navigateToSignIn = () => {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP,"");
    setStatus('signUpStatus',"");

    navigate("/login"); 
  };

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  }

  useEffect(() => {
    axios.post(API.CONTENTFUL_URL, {
      query: GET_SIGNUPPAGE_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });

    axios.post(API.BASEURL, {
      query: GET_SUPERFUND_DATA_QUERY,
    })
      .then(async (response) => {
        setsuperFundLoaded(true);
        setsuperFunds(response.data?.data);
      });

      if(window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP) == "reload"){
        setregisterStatusMessage(status.signUpStatus);
        window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP,"");
      }

  }, []);

  if (!contentDataLoaded || !superFundLoaded)
    return <GelSpinner medium overlay />

  return (
    <GelContainerLite style={{  
      paddingRight: getGelTokens().global.sizeNone,
      paddingLeft: getGelTokens().global.sizeNone,
      paddingBottom: getGelTokens().global.sizeBaseUnit * 15, 
      paddingTop:getGelTokens().global.sizeBaseUnit * 15, 
      overflow: "hidden" }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : getGelTokens().global.sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: getGelTokens().global.sizeBaseUnit * 50
          }}
          fieldGutter={getGelTokens().global.sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          parseResponseBody={false}
          style={{ 
            paddingRight: getGelTokens().global.sizeBaseUnit * 16, 
            paddingLeft: getGelTokens().global.sizeBaseUnit * 16 }}>
          <GelRowLayout gutter="medium" style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 3 }}>
            <GelHeading3>
              {contentfulData?.mmcSignUpPageCollection.items[0]?.headerText}
            </GelHeading3>
            <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseUnit }}>{contentfulData?.mmcSignUpPageCollection?.items[0]?.subText}</GelParagraph>

            <GelFormField
              label={contentfulData?.mmcSignUpPageCollection.items[0]?.superFundText}
            >
              <GelSelect
                name="superfund"
                onChange={onSuperFundChange}
                placeholder="Please select"
                options={superFunds?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                  { label: superFund.superfundName, value: superFund.superfundId }
                ))}
                required
                requiredErrorMsg={contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.superFundReqMsg}
              />
            </GelFormField>
            <GelFormField label={contentfulData?.mmcSignUpPageCollection.items[0]?.memberNumberText} >
              <GelTextInput
                name="memberNumber"
                onChange={onFormDataChange}
                required
                value={formData.memberNumber}
                requiredErrorMsg={contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.memberNumberReqMsg} />
            </GelFormField>
            <GelFormField
              label={contentfulData?.mmcSignUpPageCollection.items[0]?.dateOfBirthText} >
              <GelStandardDateInput
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={onFormDataChange}
                required
                lt="9999-12-31"
                errorMsg={{
                  required: `${contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.dobReqMsg}`,
                  compare: `${contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.dobFormatMsg}`,
                  format: `${contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.dobFormatMsg}`
                }}
              />
            </GelFormField>
            {isCaptchaEnabled &&
              <GelFormField
                label={contentfulData?.mmcSignUpPageCollection.items[0]?.captchaText}>
                <GelReCaptcha
                  name="reCaptchaToken"
                  sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                  onChange={onFormDataChange}
                  errorMsg={{
                    required: `${contentfulData?.mmcSignUpPageCollection.items[0]?.errorMessageContent.captchaReqMsg}`,
                  }}
                />
              </GelFormField>}

            {registerStatusMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={getGelTokens().global.themeColorIconDanger} name="AlertCircle" inline />
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                  {registerStatusMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            {registerSuccessMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={getGelTokens().global.themeColorIconSuccess} name="CheckSmall" inline />
                <GelLabel style={{ color: getGelTokens().global.themeColorTextSuccess }}>
                  {registerSuccessMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            {!registerInProgress &&
              <GelFormField width="auto" style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4}}>
                <GelButton
                  name="" style={{
                    width: "100%"
                  }}
                  primary large submit
                >
                  {contentfulData?.mmcSignUpPageCollection.items[0]?.continueButtonText}
                </GelButton>
              </GelFormField>}

            {registerInProgress &&
              <GelSpinner medium overlay={true} />}

            <GelLabel style={{
              fontWeight: 100,
              paddingRight: getGelTokens().global.sizeBaseX2
            }}> {contentfulData?.mmcSignUpPageCollection.items[0]?.signInText}
              <GelLink onClick={navigateToSignIn}>  {contentfulData?.mmcSignUpPageCollection.items[0]?.signInLink}</GelLink>
            </GelLabel>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner></FundBanner>}
      </GelBoxLayout>
    </GelContainerLite>
  );
}

export default SignUpPage;