import React, { useContext } from 'react';
import {
    GelScreenDetectorContext,
    GelContainerLite
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

const ResponsiveContainer = (props) => {
    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    
    const getContainerWidth = () => {
        if (isLargerThanLgScreen(screen)) {
            return `${getGelTokens().global.gridContainerXLargeMaxWidth}px`;
        }
        else
            return '100%';
    }

    return (<GelContainerLite 
        style={{ 
            maxWidth: getContainerWidth(), 
            margin: 'auto', 
            paddingLeft: `0px`,
            paddingRight: `0px`,
            ...props.style
        }}>
        {props.children}
    </GelContainerLite>)
}

export default ResponsiveContainer;