import { GelIcon, GelCaption } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import styled from "@emotion/styled";
import React from "react";

const tokens = getGelTokens();

type Props = {
  count: number;
  page: number;
  onChange: (page: number) => void;
  siblingItemCount?: number;
  edgeItemCount?: number;
  disabled?: boolean;
} & Omit<React.ComponentProps<"nav">, "onChange">;

const CHAR_HORIZONTAL_ELLIPSIS = "\u2026";

const generateArrayFromRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

// mimicking behaviour of https://mui.com/material-ui/react-pagination/
// cleared with Dhiraphon Dhiravarangkura from the design team
const generateListItems = (
  count: number,
  page: number,
  edgeItemCount: number,
  siblingItemCount: number,
) => {
  if (count <= 7) {
    return Array.from({ length: count }, (_, i) => i + 1);
  }

  //generate numbers before the theoretical first ellipse
  const boundaryItemsBefore = generateArrayFromRange(
    1,
    Math.min(edgeItemCount, count),
  );

  //generate numbers after the theoretical second ellipse
  const boundaryItemsAfter = generateArrayFromRange(
    Math.max(count - edgeItemCount + 1, edgeItemCount + 1),
    count,
  );

  //generate the starting point of the center chunk
  const siblingNumBefore = Math.max(
    Math.min(
      page - siblingItemCount,
      count - edgeItemCount - siblingItemCount * 2 - 1,
    ),
    edgeItemCount + 2,
  );

  //generate the ending point of the center chunk
  const siblingNumAfter = Math.min(
    Math.max(page + siblingItemCount, edgeItemCount + siblingItemCount * 2 + 2),
    boundaryItemsAfter.length > 0 ? boundaryItemsAfter[0] - 2 : count - 1,
  );

  //generate the center chunk
  const centerItems = generateArrayFromRange(siblingNumBefore, siblingNumAfter);

  // if we need a left side ellipse, generate it
  const ellipsisItemsBefore =
    siblingNumBefore > edgeItemCount + 2
      ? [CHAR_HORIZONTAL_ELLIPSIS]
      : edgeItemCount + 1 < count - edgeItemCount
        ? [edgeItemCount + 1]
        : [];

  // if we need a right side ellipse, generate it
  const ellipsisItemsAfter =
    siblingNumAfter < count - edgeItemCount - 1
      ? [CHAR_HORIZONTAL_ELLIPSIS]
      : count - edgeItemCount > edgeItemCount
        ? [count - edgeItemCount]
        : [];

  // stitch everything together and return
  return [
    ...boundaryItemsBefore,
    ...ellipsisItemsBefore,
    ...centerItems,
    ...ellipsisItemsAfter,
    ...boundaryItemsAfter,
  ];
};

export const Pagination = ({
  count,
  page,
  onChange,
  edgeItemCount = 1,
  siblingItemCount = 1,
  disabled = false,
  ...rest
}: Props) => {
  const onPaginationItemClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => {
    event.stopPropagation();
    event.currentTarget.blur();
    if (newPage > 0 && newPage !== page) {
      onChange(newPage);
    }
  };

  const listItems = generateListItems(
    count,
    page,
    edgeItemCount,
    siblingItemCount,
  );
  const isFirstPage = page === 1;
  const isLastPage = page === count;

  return (
    <StyledNav {...rest}>
      <StyledList data-testid="pagination-list">
        <StyledListItem
          {...(isFirstPage || disabled ? { disabled: true } : {})}
          key={0}
        >
          <StyledButton
            type="button"
            aria-label="Navigate to previous page"
            onClick={(e) => onPaginationItemClick(e, page - 1)}
            {...(isFirstPage || disabled
              ? { tabIndex: -1, disabled: true }
              : {})}
            data-testid="pagination-prev"
            selected={isFirstPage}
          >
            <StyledButtonIcon
              aria-hidden="true"
              name="ArrowLeft"
              width="16"
              color="currentColor"
            />
            <StyledButtonText as="span">Previous</StyledButtonText>
          </StyledButton>
        </StyledListItem>
        {listItems.map((item, index) => (
          <StyledListItem key={index + 1}>
            <StyledPageButton
              type="button"
              aria-label={
                item === CHAR_HORIZONTAL_ELLIPSIS ? "Ellipsis" : `Page ${item}`
              }
              aria-current={item === page}
              onClick={(e) =>
                onPaginationItemClick(e, typeof item === "number" ? item : 0)
              }
              {...(item === CHAR_HORIZONTAL_ELLIPSIS || disabled
                ? { disabled: true }
                : {})}
              selected={item === page}
              data-testid={`pagination-page-${item}`}
            >
              <StyledButtonText as="span">{item}</StyledButtonText>
            </StyledPageButton>
          </StyledListItem>
        ))}
        <StyledListItem
          {...(isLastPage || disabled ? { disabled: true } : {})}
          key={count + 1}
        >
          <StyledButton
            type="button"
            aria-label="Navigate to next page"
            onClick={(e) => onPaginationItemClick(e, page + 1)}
            {...(isLastPage || disabled
              ? { tabIndex: -1, disabled: true }
              : {})}
            data-testid="pagination-next"
            selected={isLastPage}
          >
            <StyledButtonText as="span">Next</StyledButtonText>
            <StyledButtonIcon
              aria-hidden="true"
              name="ArrowRight"
              width="16"
              color="currentColor"
            />
          </StyledButton>
        </StyledListItem>
      </StyledList>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  border-top: 1px solid ${tokens.global.themeColorGrayT20};
`;

export const StyledList = styled.ul({
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexFlow: "row nowrap",
  });
  
  export const StyledListItem = styled.li(() => ({
    [`@media (max-width: ${tokens.global.breakpointSmall}px)`]: {
      display: "none",
    },
    
    "&:first-of-type": {
      marginRight: "auto",
      
      [`@media (max-width: ${tokens.global.breakpointSmall}px)`]: {
        display: "flex",
      },
    },
    
    "&:last-of-type": {
      marginLeft: "auto",
      
      [`@media (max-width: ${tokens.global.breakpointSmall}px)`]: {
        display: "flex",
      },
    },
  }));
  
  export const StyledButton = styled.button<{ selected: boolean }>(({ selected }) => ({
    all: "unset",
    cursor: "pointer",
    boxSizing: "border-box",
    height: "36px",
    minWidth: "36px",
    display: "flex",
    gap: "4px",
    alignItems: "center",
    paddingTop: `${tokens.global.sizeBaseX4}px`,
    paddingLeft: `${tokens.global.sizeBase}px`,
    paddingRight: `${tokens.global.sizeBase}px`,
    userSelect: "none",
    color: selected ? `${tokens.global.themeColorTextLink}` : tokens.global.themeColorTextLight,
  
    "&:disabled": {
      cursor: "default",
      border: 0,
      color: `${tokens.global.themeColorGrayT20}`,
    },
  
    "&:hover:enabled, &:focus:enabled": {
      color: `${tokens.global.themeColorInteractive1Active}`,
    },
  
    [`${StyledListItem}:first-of-type &`]: {
      margin: "auto auto 0 0",
    },
  
    [`${StyledListItem}:last-of-type &`]: {
      margin: "auto 0 0 auto",
    },
  }));
  
  export const StyledPageButton = styled(StyledButton)(({ selected }) => ({
    paddingLeft: `${tokens.global.sizeBaseX4}px`,
    paddingRight: `${tokens.global.sizeBaseX4}px`,
  
    boxShadow: selected ? `inset 0 2px ${tokens.global.themeColorTextLink}` : 0,
  
    "&:hover:enabled, &:focus:enabled": {
      boxShadow: `inset 0 2px ${tokens.global.themeColorInteractive1Active}`,
    },
  }));
  
  export const StyledButtonText = styled(GelCaption)({
    display: "block",
    textTransform: "none",
    color: "inherit",
  
    [`${StyledListItem}[disabled] &`]: {
      color: `${tokens.global.themeColorGrayT20}`,
    },
  });
  
  export const StyledButtonIcon = styled(GelIcon)({
    display: "block",
    margin: "auto auto 0 auto",
  });

export default Pagination;