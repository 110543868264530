import React, { useContext, useEffect, useState } from 'react';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelHeading2,
    GelParagraph,
    GelSpinner
} from '@tal-gel/components';

import axios from 'axios';
import {AdobePageName, API} from '../../constants/constants';
import { GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import SupportLovedOnes from './supportLovedOnes';
import SupportInRecovery from './supportInRecovery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../common/usercontext/user.context';
import HealthConnectorCampaign from './healthConnectorCampaign';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import { AdodeAnalytics } from '../../common/analytics/adobe-analytics';

const SupportContainer = () => {
    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [contentfulData, setcontenfulData] = useState<null | { mmcHealthAndRecoverySupportCollection: any }>(null)
    const [ssoCookies] = useCookies(['tpid']);
    
    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid
            }
        })
            .then(async (response) => {
                setcontenfulData(response.data?.data);
            });
            AdodeAnalytics.PushPageLoadEvent(ssoCookies.tpid, AdobePageName.HealthSupport);
    }, []);

    if (!contentfulData )
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: getGelTokens().global.sizeBaseX2
    }));

    const showHealthConnector = contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorCampaignImage;

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX10}px`,
        backgroundColor: getGelTokens().global.themeColorBackgroundLight
    }));

    return (
        <GelContainerLite style={{ padding: getGelTokens().global.sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].pageHeader}</GelHeading2>
                <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseX3 }}>{contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].pageSubtext}</GelParagraph>
            </PageHeader>

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX10}px` }}>
                <SupportLovedOnes contentData={contentfulData}></SupportLovedOnes>
            </GelRowLayout>

            {showHealthConnector ? <HealthConnectorCampaign contentData={contentfulData}></HealthConnectorCampaign> : <Spacer></Spacer>}

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX10}px` }}>
                <SupportInRecovery contentData={contentfulData}></SupportInRecovery>
            </GelRowLayout>

        </GelContainerLite>
    );
};

export default SupportContainer;