import React, { useContext, useEffect, useState } from 'react';
import {
  GelBoxLayout,
  GelParagraph,
  GelContainerLite,
  GelIcon,
  GelLabel,
  GelLink,
  GelTag
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AdodeAnalytics } from '../../common/analytics/adobe-analytics';
import { AdobeClickEvents, AdobePageName } from '../../constants/constants';

const Requiremnet = (Props) => {

    let isPendingRequirement : Boolean = false;
    const navigate = useNavigate();
    const [claimCookie, setClaimCookie] = useCookies(['selectedClaim']);
    const [ssoCookies] = useCookies(["tpid"]);
    
    const navigateToDetails = (reqId,reqName) => {
        setClaimCookie('selectedClaim',  Props.claimType);
        AdodeAnalytics.PushUserClickEvent(ssoCookies.tpid, AdobeClickEvents.LinkClick, AdobePageName.ClaimsDashboard,Props.claimType, reqName);

        navigate("/claimdetails", { state: {selectedNotification: reqId } });
      };

    const isPendingRequirements = Props.requirementList?.map((req, index) => {   
    if(!req.completedDate){
        isPendingRequirement = true;
        } 
    });

    const requirements = isPendingRequirement ?
     Props.requirementList.filter(req => !req.completedDate).map((req, index) =>
        <GelBoxLayout key={req.id} alignmentIndividual={['center', null]} space={[7, 0.1, 0.1]} 
            style={{
                backgroundColor: getGelTokens().global.themeColorBackgroundLight,
                paddingTop: getGelTokens().global.sizeBaseX3,
                paddingBottom: getGelTokens().global.sizeBaseX3,
                paddingLeft: getGelTokens().global.sizeBaseX4,
                border: `0px solid ${getGelTokens().global.themeColorGrayT10}`,
                borderWidth: `${index == 0 ? 1 : 0}px 0px 1px`
            }}
            childStyle={{
                paddingLeft: '0px',
                paddingRight: '0px'
            }}>
            <GelLink onClick= {() => navigateToDetails(req.id,req.friendlyText)}>{req.friendlyText}</GelLink>
            { (req.receivedDate || req.associatedDocuments?.length) &&
                <GelTag small warning >
                    <GelBoxLayout 
                        distribution='center' 
                        alignment='center' 
                        space={[1, 20]} 
                        style={{ 
                            gap: `${getGelTokens().global.sizeBaseUnit}px`, 
                            paddingLeft: `${getGelTokens().global.sizeBaseX2}px`, 
                            paddingRight: `${getGelTokens().global.sizeBaseX2}px`
                        }}
                        childStyle={{
                            paddingLeft: `${getGelTokens().global.sizeNone}px`,
                            paddingRight: `${getGelTokens().global.sizeNone}px`
                        }}>
                        <GelIcon
                            name="Circle"
                            width={getGelTokens().global.sizeBaseUnit * 2}
                            color={getGelTokens().global.themeColorBorderWarning}></GelIcon>
                        <GelParagraph style={{ fontSize: getGelTokens().global.sizeBaseX3, color:getGelTokens().global.themeColorTextDark }}> {Props.requirementReviewStatus}</GelParagraph>
                    </GelBoxLayout>                   
                </GelTag>
            }
             {!req.receivedDate && !req.associatedDocuments?.length &&
                <GelTag small danger >
                    <GelBoxLayout
                        distribution='center' 
                        alignment='center' 
                        space={[1, 20]} 
                        style={{ 
                            gap: `${getGelTokens().global.sizeBaseUnit}px`, 
                            paddingLeft: `${getGelTokens().global.sizeBaseX2}px`, 
                            paddingRight: `${getGelTokens().global.sizeBaseX2}px`
                        }}
                        childStyle={{
                            paddingLeft: `${getGelTokens().global.sizeNone}px`,
                            paddingRight: `${getGelTokens().global.sizeNone}px`
                        }}>
                        <GelIcon
                            name="Circle"
                            width={getGelTokens().global.sizeBaseUnit * 2}
                            color={getGelTokens().global.themeColorBorderDanger}></GelIcon>
                         {Props.requirementInCompleteStatus}
                    </GelBoxLayout>              
                </GelTag>
            }
        </GelBoxLayout>            
    )
    :   
    <GelBoxLayout alignmentIndividual={['center', null]} space={[7, 0.1, 0.1]} style={{
        backgroundColor: getGelTokens().global.themeColorBackgroundLight,
        paddingTop: getGelTokens().global.sizeBaseX3,
        paddingBottom: getGelTokens().global.sizeBaseX3,
        paddingLeft: getGelTokens().global.sizeBaseX3,
        border: `0px solid ${getGelTokens().global.themeColorGrayT20}`,
        borderWidth: `1px 0px 1px`}}>
         {!isPendingRequirement && Props.thirdPartyRequirement && 
            <GelLabel>{Props.thirdPartyRequirementStatus}</GelLabel>
         }
         {!isPendingRequirement && !Props.thirdPartyRequirement && 
            <GelLabel>{Props.requirementStatus}</GelLabel>
         }
    </GelBoxLayout>

    return (
        <GelContainerLite gutter="medium" style={{
            paddingBottom: getGelTokens().global.sizeNone,
            paddingTop: getGelTokens().global.sizeNone,
            paddingLeft: getGelTokens().global.sizeBaseX2,
            paddingRight: getGelTokens().global.sizeBaseX2,
        }}>    
            {requirements}
        </GelContainerLite>
      );
};

export default Requiremnet;