import {
    GelForm,
    GelButton,
    GelContainerLite
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useEffect, useState } from "react";
import { Activities, MemberClaims, AssociatedDocuments } from "../../../common/models/memberclaims";
import { FormatHelper } from '../../../common/formatHelper.common';
import { ACTIVITY_TYPE } from "../../../constants/constants";
import styled from "@emotion/styled";
import ClaimHistoryItem from "./claimHistory-item";

interface ClaimHistoryProps {
    claimDetails: MemberClaims
    contentData: any
}

const Footer = styled.div(() => ({
    height: getGelTokens().global.sizeBaseX2 * 10,
    color: getGelTokens().global.themeColorBackgroundDefault,
    paddingTop: getGelTokens().global.sizeBaseX2
}));

const ClaimHistory = (props: ClaimHistoryProps) => {
    const {
        claimDetails,
        contentData
    } = props;
    const [rowCount, setRowCount] = useState(0);
    const [claimActivityDetailsPage, setclaimActivityDetailsPage] = useState(new Array<Activities>());
    const [claimActivityDetailsFull,setclaimActivityDetailsFull] = useState(new Array<Activities>());

    const LoadNextPage = () => {

        if (rowCount <= claimActivityDetailsFull.length) {
            setRowCount(rowCount + 5);
            setclaimActivityDetailsPage(claimActivityDetailsFull.slice(0, rowCount));
        }
    }

    const setClaimHistoryRecords = () => {
        let claimActivityDetails = new Array<Activities>();
    
        claimDetails?.activities?.filter(ac => !ac.hideActivity).map((activity) => {

            if (activity.type === ACTIVITY_TYPE.REQUIREMENT) {

                /* logic for creating a duplicate activity in runtime for those requiments which are completed. ex: required document uploaded. */
                const activityDetails: Activities = {
                    claimPaymentId: 0,
                    hideActivity: false,
                    title: props.contentData?.mmcClaimDetailsCollection?.items[0].requirementRaisedText,
                    message: FormatHelper.stringFormat(props.contentData?.mmcClaimDetailsCollection?.items[0].requirementRaisedMessage, activity.friendlyText),
                    description: activity.description,
                    type: activity.type,
                    date: activity.date,
                    friendlyText: activity.friendlyText,
                    documents: new Array<AssociatedDocuments>()
                };

                var matchedRequirement = claimDetails.requirements.find(f => f.description === activity.friendlyText && f.completedDate);
                if (matchedRequirement) {
                    activity.title = props.contentData?.mmcClaimDetailsCollection?.items[0].requirementCompletedText;
                    activity.message = FormatHelper.stringFormat(props.contentData?.mmcClaimDetailsCollection?.items[0].requirementCompletedMessage,
                        activity.friendlyText);
                    activity.documents = new Array<AssociatedDocuments>();
                    matchedRequirement?.associatedDocuments?.map((document) => {
                        activity.documents.push(document);
                    })

                    activityDetails.date = matchedRequirement?.requestedDate!;
                    claimActivityDetails.push(activity);
                }
                claimActivityDetails.push(activityDetails);
            }
            else if (activity.type === ACTIVITY_TYPE.PAYMENT) {
                activity.hideActivity = false;
                activity.title = props.contentData?.mmcClaimDetailsCollection?.items[0].paymentText;
                activity.message = FormatHelper.stringFormat(props.contentData?.mmcClaimDetailsCollection?.items[0].paymentMessage,
                    activity.friendlyText, FormatHelper.formatDate(activity.date));
                claimActivityDetails.push(activity);
            }
            else if (activity.type === ACTIVITY_TYPE.ACTIVITY) {
                activity.hideActivity = false;
                activity.title = props.contentData?.mmcClaimDetailsCollection?.items[0].claimApprovedHeader;
                activity.message = `${activity.description.replace("Approved","approved")} on ${FormatHelper.formatDate(activity.date)}`;
                claimActivityDetails.push(activity);
            }
        })

        claimActivityDetails.sort((a, b) => FormatHelper.toDate(b.date).getTime() - FormatHelper.toDate(a.date).getTime());
        setclaimActivityDetailsFull(claimActivityDetails);
    }

    useEffect(() => {
        setClaimHistoryRecords();
        if(rowCount == 0)
            setRowCount(5);
        
        setclaimActivityDetailsPage(claimActivityDetailsFull.slice(0, rowCount));
    },[rowCount]);

    return (
        <div style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 4, paddingBottom: getGelTokens().global.sizeBaseUnit * 5 }}>
            <GelContainerLite style={{
                borderBottom: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderTop: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                width: "auto",
                cursor: "pointer",
                marginLeft: getGelTokens().global.sizeBaseUnit * 6,
                marginRight: getGelTokens().global.sizeBaseUnit * 6
            }}>
                <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}>
                    {claimActivityDetailsPage.map((activity) =>
                        <>
                            <ClaimHistoryItem key={Math.random()} activity={activity} marginNeeded={rowCount > 5}></ClaimHistoryItem>
                            <div style={{ border: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorGrayT05}` }}></div>
                        </>
                    )}
                </div>
                {rowCount < claimActivityDetailsFull.length &&
                    <Footer>
                        <GelForm onSubmit={LoadNextPage}>
                            <GelButton style={{ width: "100%" }} primary large submit>
                                {props.contentData?.mmcClaimDetailsCollection?.items[0].historyLoadMoreButtonText}
                            </GelButton>
                        </GelForm>
                    </Footer>}
            </GelContainerLite>
        </div>
    );
};

export default ClaimHistory;
