import React, { useContext, useEffect, useState } from 'react';
import { getGelTokens } from "@tal-gel/theming";
import {
    GelLink,
    GelBoxLayout,
    GelContainerLite,
    GelLabel
 
} from '@tal-gel/components';
import { useNavigate } from "react-router-dom";
import { NotificationItemIds } from '../../constants/constants';
import { useCookies } from 'react-cookie';

interface documentUploadProps {
    uploadDocumentText: string;
    uploadDocumentDescriptor: string;
    title: string
}

const DocumentUpload = (props: documentUploadProps) => {
    const navigate = useNavigate();
    const [claimCookie, setClaimCookie] = useCookies(['selectedClaim']);
    
    const onClick = () => {
        setClaimCookie('selectedClaim',  props.title);
        navigate("/claimdetails", { state: { selectedNotification: NotificationItemIds.OtherDocuments } });
      };

    return (
        <GelContainerLite gutter="medium" style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: getGelTokens().global.sizeBaseX2 * 1,
            paddingRight: getGelTokens().global.sizeBaseX2 * 1,
        }}>    
             <GelBoxLayout   alignmentIndividual={['center', null]} 
            style={{
                backgroundColor: getGelTokens().global.themeColorBackgroundLight,
                paddingTop: getGelTokens().global.sizeBaseX3,
                paddingBottom: getGelTokens().global.sizeBaseX3,
                paddingLeft: getGelTokens().global.sizeBaseX3,
                borderColor: getGelTokens().global.themeColorGrayT20,
            }}>
            <GelLabel>{props.uploadDocumentDescriptor}
            <GelLink style={{
            paddingLeft: getGelTokens().global.sizeBaseX2 / 2
        }} onClick={onClick}>{props.uploadDocumentText}</GelLink></GelLabel>
            
        </GelBoxLayout> 
        </GelContainerLite>
       
    );
};

export default DocumentUpload;