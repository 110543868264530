import React, { useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link, useLocation } from "react-router-dom";
import {
    NavItemList,
    NavMenuMobContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    EnabledMenuLableStyle,
    ActiveMenuLableStyle,
    StyledListHeader,
    StyledListItem
} from "../../styles/nav.styles";
import { NavProps } from "./nav-props";
import { useCookies } from 'react-cookie';
import { AdodeAnalytics } from '../../../common/analytics/adobe-analytics';
import { AdobeClickEvents, AdobePageName, MESSAGE_HUB_NAV_KEY } from '../../../constants/constants';
import DigiCorroBadge from './digiCorro-badge';

const TopNavMob = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {
    const[selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const location = useLocation();
    const [ssoCookies] = useCookies(["tpid"]);
    
    const onMenuItemClick = (itemId) => {
        const item = textData.menuItems.find(item => item.id == itemId);
        setSelectedMenuItem(item);
        AdodeAnalytics.PushNavClickEvent(ssoCookies.tpid, AdobePageName.MenuItem,item.text);
        onMenuHideClick();
    };

    useEffect(() => {

        if(location.pathname == "/help")
            setSelectedMenuItem(null);

        setSelectedMenuItem(activeMenuItem)
    })
    
    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelLabel style={{
                        color: getGelTokens().global.themeColorWhite,
                        fontWeight: getGelTokens().global.fontWeightBold
                    }}>
                         {selectedMenuItem ? selectedMenuItem.text.toUpperCase() : "NEED HELP"}
                    </GelLabel>
                    <GelIcon
                        name="MinimalUp"
                        width={getGelTokens().global.sizeBaseUnit * 6}
                        color={getGelTokens().global.themeColorWhite}
                        style={{ float: "right" }}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuMobContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                 style= { (menuItem.id == selectedMenuItem?.id) ? ActiveMenuStyle: EnabledMenuStyle} >
                                <GelIcon
                                    name={menuItem.icon}
                                    color={(menuItem.id == selectedMenuItem?.id) ? getGelTokens().global.themeColorBackgroundSuccess :
                                        getGelTokens().global.themeColorGrayT30}
                                    width={getGelTokens().global.sizeBaseUnit * 6}
                                    style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 2 }}
                                />
                               <GelLabel id={menuItem.text} style={ (menuItem.id == selectedMenuItem?.id) ? ActiveMenuLableStyle: EnabledMenuLableStyle }>
                                    {menuItem.text}
                                </GelLabel>
                                {menuItem.key === MESSAGE_HUB_NAV_KEY ? <DigiCorroBadge /> : null}
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuMobContainer>
        </GelRowLayout>
    );
};

export default TopNavMob;