import React, { useEffect, useState } from "react";
import {
    GelParagraph,
    GelCollapsible,
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from '@tal-gel/components';
import { FormatHelper } from "../../../common/formatHelper.common";
import { useLocation } from "react-router-dom";
import { AdobeClickEvents, AdobePageName, NotificationItemIds } from "../../../constants/constants";
import { getGelTokens } from '@tal-gel/theming';
import { useCookies } from "react-cookie";
import { AdodeAnalytics } from "../../../common/analytics/adobe-analytics";

const NextFuturePayment = (props) => {
    const location = useLocation();
    const [ssoCookies] = useCookies(['tpid']);

    const [showFuturePayment, setshowFuturePayment] = useState(false);
    const onHeaderClick = () => {
        setshowFuturePayment(!showFuturePayment);
        AdodeAnalytics.PushAccordionClickEvent(ssoCookies.tpid, AdobeClickEvents.AccordionClick, AdobePageName.ClaimDetails, 
            props.contentData?.mmcClaimDetailsCollection?.items[0]?.nextFuturePaymentHeader);
    }
    useEffect(() => {
        var selectedNotification = location.state?.selectedNotification;
        setshowFuturePayment(selectedNotification == NotificationItemIds.FuturePayment);
    }, []);
    
   
    return (
        <div id={NotificationItemIds.FuturePayment}>
            <GelContainerLite style={{
                borderTop: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                paddingTop: getGelTokens().global.sizeBaseUnit,
                paddingBottom: getGelTokens().global.sizeBaseUnit,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>

                <GelBoxLayout space={[7, 0.1, 0.04]} style={{
                    borderBottom: showFuturePayment ? `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}` : "",
                    width: "auto",
                    padding: getGelTokens().global.sizeBaseUnit * 2,
                    marginLeft: -getGelTokens().global.sizeBaseUnit * 4,
                    marginRight: -getGelTokens().global.sizeBaseUnit * 4,
                }}>
                    <GelLabel style={{
                        fontFamily: getGelTokens().global.fontFamilySans,
                        fontSize: getGelTokens().global.sizeBaseUnit * 4,
                        fontWeight: getGelTokens().global.fontWeightRegular
                    }}>
                        {props.contentData?.mmcClaimDetailsCollection?.items[0]?.nextFuturePaymentHeader}
                    </GelLabel>

                    <GelIcon name={showFuturePayment ? "ChevronUp" : "ChevronDown"} color={getGelTokens().global.themeColorIconDefault} width="20px" height="20px"></GelIcon>
                </GelBoxLayout>
            </GelContainerLite>

            <GelContainerLite style={{
                borderBottom: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                width: "auto"
            }}>

                <GelCollapsible open={showFuturePayment}>
                    <GelContainerLite style={{ padding: getGelTokens().global.sizeBaseUnit * 4 }}>
                        <GelParagraph>
                            {FormatHelper.stringFormat(props.contentData?.mmcClaimDetailsCollection?.items[0]?.nextFuturePaymentBody,
                                props.futurePayment?.friendlyText, props.futurePayment?.description)}
                        </GelParagraph>
                    </GelContainerLite>
                </GelCollapsible>

            </GelContainerLite>
        </div>
    )
};

export default NextFuturePayment;