import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './setUpTalGel'
import { UserContextProvider } from './common/usercontext/user.context';
import GelThemes from './gelThemes';
import { ThemeContextProvider } from './common/usercontext/themes.context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const IndexPage = () => {

  return (
    <ThemeContextProvider>
      <UserContextProvider>
          <GelThemes />
      </UserContextProvider>
    </ThemeContextProvider>
  );
}


root.render(
  <IndexPage />
);

reportWebVitals();
