import React, { useEffect, useState } from "react";
import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelSpinner
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useLocation } from "react-router-dom";
import axios, { HttpStatusCode } from "axios";
import { useCookies } from "react-cookie";
import { API } from "../../constants/constants";
import { GET_ERROR_PAGES_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";

const ServerError = () => {
    const location = useLocation();
    const [ssoCookies] = useCookies(["tpid"]);
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | {
        mmcErrorPagesCollection: any;
    }>(null);
    
    useEffect(() => {
        axios
          .post(API.CONTENTFUL_URL, {
            query: GET_ERROR_PAGES_CONTENT_QUERY,
            variables: {
              fundName: ssoCookies.tpid,
            },
          })
          .then(async (response) => {
            setcontentDataLoaded(true);
            setcontenfulData(response.data?.data);
          });
      }, []);

      if (!contentDataLoaded) 
        return <GelSpinner medium overlay />;

    const ErrorMessage = () => {    
        if(location.state && location.state.errorCode === HttpStatusCode.Unauthorized){
            return (          
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                    {contentfulData?.mmcErrorPagesCollection?.items[0].sessionTerminatedMessage}
                </GelLabel>
            )
        }
        else if(location.state && location.state.errorCode === HttpStatusCode.Forbidden){
            return (          
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                    {contentfulData?.mmcErrorPagesCollection?.items[0].forbiddenMessage}
                </GelLabel>
            )
        }
        else
        {
            return (          
                <GelLabel style={{ color: getGelTokens().global.themeColorTextDanger }}>
                    <GelIcon color={getGelTokens().global.themeColorTextDanger} name="AlertCircle" inline />
                    {contentfulData?.mmcErrorPagesCollection?.items[0].unHandledErrorMessage}
                </GelLabel>
            )
        }
        
    }
    
    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <ErrorMessage/>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default ServerError;