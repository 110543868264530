import {
    GelLabel,
    GelBoxLayout,
    GelContainerLite,
    GelIcon,
    GelCollapsible,
    GelHeading6,
    GelParagraph
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useState, useContext } from "react";
import { GelScreenDetectorContext } from "@tal-gel/components";

interface ContactUsAlertProps {
    title: string;
    body: string;
    style?: React.CSSProperties; // Optional style prop
}

const ContactUsAlert = (props: ContactUsAlertProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    return (
        <div style={{padding: '16px', borderRadius: '4px', border: '1px', background: '#D5F0F0', ...props.style}}>
            <GelHeading6 style={{fontSize: '15px'}}>{props.title}</GelHeading6>
            <GelParagraph style={{color: getGelTokens().global.themeColorTextDark }}><div dangerouslySetInnerHTML={{ __html: props.body }} /></GelParagraph>
        </div>
    );
};

export default ContactUsAlert;

