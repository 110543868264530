import { useOktaAuth } from "@okta/okta-react";
import config from "../../config";

export const AccountApi = {
  isAuthenticated: false,
  memberNumber: null,
  async sendMfaCode(factorId: string, stateToken: string) {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stateToken })
    };

    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/factors/${factorId}/verify`, requestOptions);
  },

  async verifyMfaCode(factorId:string, stateToken:string, passCode:string){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stateToken,passCode })
    };

    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/factors/${factorId}/verify`, requestOptions);
  },

  async verifyRecoveryToken(recoveryToken:string) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ recoveryToken })
    };
    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/recovery/token`, requestOptions);
  },

  async resetPassword(newPassword:string, stateToken:string) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({newPassword, stateToken})
    };
    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/credentials/reset_password`, requestOptions);
  },

  async  sendMfaSms(factorId:string, stateToken:string) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stateToken })
    };
    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/factors/${factorId}/verify`, requestOptions);
  },
  async resendMfaCode(factorId:string, stateToken:string, passCode:string){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ stateToken,passCode })
    };
    return fetch(`https://${config.oktaDomain.domain}/api/v1/authn/factors/${factorId}/verify/resend`, requestOptions);
  }
}

