import React, { useContext, useEffect, useState } from "react";
import {
  GelScreenDetectorContext,
  GelContainerLite,
  GelParagraph,
  GelLink,
  GelColumnLayout
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { GET_FOOTER_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import axios from "axios";
import { API } from "../../constants/constants";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";

const Footer = () => {

  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcFooterCollection: any }>(null);
  const [ssoCookies] = useCookies(['tpid']);
  const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  useEffect(() => {
    axios.post(API.CONTENTFUL_URL, {
      query: GET_FOOTER_PAGE_CONTENT_QUERY,
      variables: {
        fundName: ssoCookies.tpid
      }
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });
  }, []);

  const Spacer = styled.div(() => ({
    paddingTop: getGelTokens().global.sizeBaseX4
  }));

  const VerticalSeperator = styled.div(() => ({
    height: getGelTokens().global.sizeBaseUnit * 6,
    width: getGelTokens().global.sizeBaseUnit / 2,
    backgroundColor: getGelTokens().global.themeColorBackgroundDefault,
    marginLeft: getGelTokens().global.sizeBaseUnit * 3,
    marginRight: getGelTokens().global.sizeBaseUnit * 3,
  }));


  return (
    <GelContainerLite gutter="medium" style={{ backgroundColor: getGelTokens().global.themeColorBackgroundDark, margin: 0, padding:0 }}>
      <GelColumnLayout distribution={isLargerThanLgScreen(screen) ? "3/4 1/4" : "1"} gutter="none" verticalGutter="none"
        style={{ padding: isLargerThanLgScreen(screen) ? "40px 20px" : "21px 15px" }}>

        <GelContainerLite style={{ padding: 0 }}>
          <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault, fontSize: getGelTokens().global.fontSizeBodySmall, fontWeight:"400" }}>
            {contentfulData?.mmcFooterCollection?.items[0]?.footerText}
          </GelParagraph>
          {!isLargerThanLgScreen(screen) ?
            <Spacer></Spacer> : undefined
          }          
        </GelContainerLite>

        <GelContainerLite style={{ display: "flex", justifyContent: !isLargerThanLgScreen(screen) ? "start" : "end", padding: 0 }}>
          <GelLink href={contentfulData?.mmcFooterCollection?.items[0]?.disclaimerLinkValue} target="_blank" >
            <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault, padding: "0px 5px 0px 0px", fontSize: getGelTokens().global.fontSizeBodyLarge, fontWeight:"400" }}>
              {contentfulData?.mmcFooterCollection?.items[0]?.disclaimerLinkText}
            </GelParagraph>
          </GelLink>
          <VerticalSeperator />
          <GelLink href={contentfulData?.mmcFooterCollection?.items[0]?.privacyLinkValue} target="_blank">
            <GelParagraph style={{ color: getGelTokens().global.themeColorBackgroundDefault, padding: "0px 5px", fontSize: getGelTokens().global.fontSizeBodyLarge, fontWeight:"400" }}>
              {contentfulData?.mmcFooterCollection?.items[0]?.privacyLinkText}
            </GelParagraph>
          </GelLink>
        </GelContainerLite>

      </GelColumnLayout>
    </GelContainerLite>
  );
};

export default Footer;