import {
    GelRowLayout,
    GelSpinner,
    GelParagraph,
    GelLink,
    GelCollapsible,
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from '../../../common/usercontext/user.context';
import axios from 'axios';
import { ACTIVITY_TYPE, API } from '../../../constants/constants';
import { MARK_NOTIFICATION_READ } from '../../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../../constants/storage.constant';

const StatusUpdates = (props) => {

    const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { contextData, setUserContext } = useContext(UserContext);
    const [notificationUpdating, setnotificationUpdating] = useState(false);

    const closeNofitication = (refreshData: boolean) => {
        let requestData = {
            notificationIds: [props.notificationId]
        };

        if (refreshData)
            setnotificationUpdating(true);

        axios.post(API.BASEURL, {
            query: MARK_NOTIFICATION_READ,
            variables: {
                markNotificationRequest: requestData
            }
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                if (refreshData) {
                    updateContext(response.data.data.markNotificationsRead.list[0]);
                    setnotificationUpdating(false);
                }
            })
    }

    const updateContext = (notification) => {
        let claim = contextData.claims.filter(claim => claim.claimId == notification?.claimId)[0];

        if (claim) {
            let index = claim.requirements.findIndex(req => req.id == notification?.notificationId);
            if (index >= 0)
                claim.requirements.splice(index, 1);

            claim.activities.filter(at => at.type == ACTIVITY_TYPE.ACTIVITY).map(x => x.hideActivity = false);
            setUserContext({ claims: contextData.claims, yourDetails: contextData.yourDetails, memberDetails: contextData.memberDetails });
        }
    }
    const [showStatusUpdate, setShowStatusUpdate] = useState(false);

    const onHeaderClick = () => {
        setShowStatusUpdate(!showStatusUpdate);
        closeNofitication(false);
    }

    return (
        <div id="processedPayment">
            <GelContainerLite style={{
                borderTop: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                paddingTop: getGelTokens().global.sizeBaseUnit,
                paddingBottom: getGelTokens().global.sizeBaseUnit,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>

                <GelBoxLayout space={[7, 0.1, 0.04]} style={{
                    borderBottom: showStatusUpdate ? `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}` : "",
                    width: "auto",
                    padding: getGelTokens().global.sizeBaseUnit * 2,
                    marginLeft: -getGelTokens().global.sizeBaseUnit * 4,
                    marginRight: -getGelTokens().global.sizeBaseUnit * 4,
                }}>
                    <GelLabel style={{
                        fontFamily: getGelTokens().global.fontFamilySans,
                        fontSize: getGelTokens().global.sizeBaseUnit * 4,
                        fontWeight: getGelTokens().global.fontWeightRegular
                    }}>
                        {props.contentData?.mmcClaimDetailsCollection?.items[0]?.claimApprovedHeader}
                    </GelLabel>

                    <GelIcon name={showStatusUpdate ? "ChevronUp" : "ChevronDown"} color={getGelTokens().global.themeColorIconDefault} width="20px" height="20px"></GelIcon>
                </GelBoxLayout>
            </GelContainerLite>

            <GelContainerLite style={{
                borderBottom: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                width: "auto"
            }}>

                <GelCollapsible open={showStatusUpdate}>
                    <GelContainerLite style={{ padding: getGelTokens().global.sizeBaseUnit * 4 }}>
                        <GelRowLayout>
                            <GelParagraph>
                                {props.activity?.description.replace("'","").replace(".","").replace("'","").replace("Approved","approved")} on {props.activity?.requestedDate}
                            </GelParagraph>
                        </GelRowLayout>
                        <GelRowLayout style={{ paddingTop: getGelTokens().global.sizeBaseUnit * 6 }} >
                            {notificationUpdating && <GelSpinner small />}
                            {!notificationUpdating &&
                                <GelLink onClick={() => closeNofitication(true)}>Close</GelLink>}
                        </GelRowLayout>
                    </GelContainerLite>
                </GelCollapsible>

            </GelContainerLite>
        </div>
    )
}

export default StatusUpdates;